import axios from "axios";
import { generateRandom } from '../utils/dummy';

const token = 'AeF2EIOhgs5AtH4l0sA0s!';
const useReq = 'get';

export const getTiles = async () => {
  const dummy = generateRandom();
  const useTxdat = encodeURI(`${token}<=>${useReq}<=>${dummy}`);
  try {
    const response = await fetch(`
    https://api.athalos.com/aos_app.php?aosicn=${useTxdat}`, {
      method: 'GET',
    }).then((res) => res.text());
   
    if (response.substring(0, 4) == '[OK]') {
      const fields = response
        .substring(4)
        .split('#~#')
        .map((field) => field.split('<=>'));
      return fields;
    } else if (response.substring(0, 7) == '[ERROR]') {
      console.log('error', response);
    }
  } catch (err) {
    console.log('kan geen verbinding met de server maken');
  }
};

export const signin = async (username, password, setErr) => {
  const dummy = generateRandom()
  try {
    const response = await fetch(
      `https://api.athalos.com/aos_app.php?aoslgn=${token}<=>${username}<=>${dummy}<=>${password}`,
      {
        method: 'GET',
      }
    ).then((res) => res.text());
    if (response.substring(0, 4) == '[OK]') {
      const fields = response.substring(4).split('<=>');
      return fields;
    } else if (response.substring(0, 7) == '[ERROR]') {
      setErr(response);
      return null;
    }
  } catch (err) {
    setErr('Kan geen verbinding maken met de server');
    return null;
  }
  
};

export const getStatic = async (type) => {
  const dummy = generateRandom()
  const langISO = 'GB';
  let useTxdat = encodeURI(`${token}<=>${useReq}<=>${langISO}<=>${dummy}`);

  let staticType;
  switch (type) {
    case 'about':
      staticType = 'aoswab';
      break;
    case 'contact':
      staticType = 'aoswco';
      break;
    default:
      break;
  }

  try {
    const response = await axios.get(`https://api.athalos.com/aos_app.php?${staticType}=${useTxdat}`);

    if (response.status === 200) {
      let data = response.data;

      if (data.substring(0, 4) === '[OK]') {
        // split the fields into clean data to send back to client
        data = data.substring(4);
        let jsonData = JSON.parse(data);

        return jsonData;
      } else if (data.substring(0, 7) === '[ERROR]') {
        // Return error message to show end-user
        throw new Error(data);
      }
    }
  } catch (error) {
    console.error("Can't connect to server...", error);
    return 'Unable to retrieve about page...';
  }
};
export const createDatabaseUser = async (username, password, role) => {
  try {
      const requestBody = {
        "ID_Key": `${username}<@>${password}`,
        "ID_Number": "00000000000000",
        "ID_Secret": password,
        "ID_Active": "1",
        "ID_Title": "Mr.",
        "ID_Fname": "TEST",
        "ID_Lname": "Admin",
        "ID_TelNumb": "31611111111",
        "ID_EmailAddr": username,
        "ID_LangIso": "NL",
        "ID_Role": "newAccount",
        "ID_Sub_Role": "TEST",
        "ID_UserPerm": "0",
        "ID_IpAtReg": "86.92.198.103",
        "ID_IpLastLogin": "77.163.123.204",
        "ID_DtLastLogin": "2024-05-21 09:36:18",
        "ID_UrlReDirect": "",
        "ID_UrlEncKey": "",
        "Msu_MainBckCol": '#846dff',
        "Msu_MainBckPic": role,
        "Msu_IconAssign": 'A01:1&A02:2&A03:3&A04:4&A05:5&A06:6&A07:7&A08:8&A09:9&A10:10&A11:11&A12:12&A13:13&MM1&MM2&MM3&MM4&RE1&RE2',
        "Msu_Branding_Logo": 'https://api.athalos.com/img/brand/athalos'
      };

      console.log('Request body:', JSON.stringify(requestBody));

      const response = await fetch('https://api.athalos.com/create_user/create_user.php', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestBody), 
      });

      if (!response.ok) {
          throw new Error('Failed to add user to the database');
      }

      console.log(response);
  } catch (error) {
      console.error('Error adding user to the database:', error);
      throw error;
  }
};