import axios from 'axios';
const qs = require('qs');
// const clientId = 'v2_simfony-web';
// const clientSecret = 'd467da0c-d8a7-4069-9481-962e51f7a28d';
const clientId = 'v2_athalos-web';
const clientSecret = 'S7uhEGa9NS';
const authUrl = 'https://login.oneworldnumber.com/auth/realms/v2_athalos/protocol/openid-connect/token';

// Base64 encode clientId and clientSecret for basic auth
const authorizationHeaderValue = btoa(`${clientId}:${clientSecret}`);
export const fetchTokenWithCredentials = async (username, password) => {
  const userCredentials = qs.stringify({
    'grant_type': 'password',
    'username': username,
    'password': password
  });

  const config = {
    method: 'post',
    url: authUrl,
    headers: {
      'Authorization': `Basic ${authorizationHeaderValue}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: userCredentials,
  };

  try {
    const response = await axios.request(config);
    const { access_token, refresh_token, refresh_expires_in } = response.data;
  
    sessionStorage.setItem('token', access_token);
    sessionStorage.setItem('refresh_token', refresh_token);
    sessionStorage.setItem('refresh_token_expiry', refresh_expires_in); 
    console.log('next_token_in' , refresh_expires_in)
    return true
  } catch (error) {
    console.error('Error fetching token with credentials:', error);
    return false
  }
};