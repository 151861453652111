import { createSlice } from '@reduxjs/toolkit';

export const gridListSlice = createSlice({
  name: 'gridList',
  initialState: {
    activeIndex: 1,
  },
  reducers: {
    setIndex: (state, action) => {
      state.activeIndex = action.payload;
    },
  },
});

export const { setIndex } = gridListSlice.actions;

export default gridListSlice.reducer;
