import HeadingPrimary from '../../components/typography/headings/heading-primary/heading-primary.component';
import no_connection_icon from '../../assets/icons/No-connection.svg';
import { FiRefreshCcw } from 'react-icons/fi';

import './noconnection.styles.scss';

const Noconnection = () => {
  return (
    <div className="no-connection">
      <div className="no-connection-header">
      <img className="no-connection-icon" src={no_connection_icon} alt="No connection icon" />
      </div>

      <div className="no-connection-content">
        <HeadingPrimary text={'Connection lost'} mode={'dark'}/>
      </div>

      <div className="no-connection-footer">
        <FiRefreshCcw size={26} className='no-connection-footer-refresh'/>
      </div>
    </div>
    
  );
};

export default Noconnection;
