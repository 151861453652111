// React/Redux imports 
import { useContext } from 'react';
import { SimContext } from '../../../api/SimContext';

// Icons
import { BiSolidBarChartAlt2 } from "react-icons/bi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdSimCard } from "react-icons/md";

// Typography
import HeadingSecondary from '../../../components/typography/headings/heading-secondary/heading-secondary.component';


import '../../../screens/homescreen/homescreen.styles.scss';

const SimOverviewComponent = () => {
  const { simCardStatus } = useContext(SimContext);
  let totalSimcards = simCardStatus.offlineInactive + simCardStatus.offlineActive + simCardStatus.onlineActive;


  return (
          <div className='home-container'>
            <HeadingSecondary text={`OWN`} showArrow={true} link={true} linkTo={'/overview'} mode={'dark'} secondLineText={'Use this screen to keep track of data and manage your SIM cards'}/>
            <div className='own-tiles-container'>
              <div className='own-block'>
                <div className='icon-container'>
                  <BiSolidBarChartAlt2 size={37} color='#fff'/>
                </div>
                <div className="usage-info">
                  <div className="usage-numbers">534/1000</div>
                  <div className="usage-label">Total Usage (MB)</div>
                </div>
                <div className="arrow-container">
                  <MdKeyboardArrowRight size={37} color='#fff'/>
                </div>
              </div>
              <div className='own-block-2'>
                <div className='icon-container'>
                  <MdSimCard size={37} color='var(--dynamic-color)'/>
                </div>
                <div className="usage-info">
                  <div className="usage-numbers-1">{totalSimcards}</div>
                  <div className="usage-label-1">Total Sims</div>
                </div>
                <div className="arrow-container">
                  <MdKeyboardArrowRight size={37} color='var(--heading-color-dark)'/>
                </div>
              </div>     
            </div>
          </div>
  );
};

export default SimOverviewComponent;
