import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import HeadingPrimary from '../../components/typography/headings/heading-primary/heading-primary.component';
import HeadingSecondary from '../../components/typography/headings/heading-secondary/heading-secondary.component';
import Sidebar from '../../components/sidebar/sidebar.component';
import './prsscreen.styles.scss';
import HeaderComponent from '../../components/header-component/header.component';

const PrsScreen = () => {
  const showSidebar = useSelector((state) => state.ui.sidebarOpen)  
  const isMobile = window.innerWidth <= 766;  
  const [showSidebarMobile, setShowSidebarMobile] = useState(false);
  return (
    <div className='header-layout'>
      <div className="bottom-nav-container">
        <HeaderComponent showSidebarMobile={showSidebarMobile}
        setShowSidebarMobile={setShowSidebarMobile} />
      </div>
      <div className='homescreen-layout'>
      {isMobile ? (
        <div className={`sidebar-container-mobile ${showSidebarMobile ? 'sidebar-visible' : ''}`}>
          <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
            <Sidebar />
          </div>
        </div>
      ) : (
        <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
          <Sidebar />
        </div>
      )}
          <div className={ 'content-container'}>
          <HeadingPrimary text={'Store'} />
            <div className='prs-container'>
              <HeadingSecondary text={'PRS'} />
              <div className="sub-container">
              <iframe
                title="Embedded Website"
                src="http://212.61.52.115/octo_portprst/index.php"
                width="100%"
                height="1300px"
                className='prs-iframe'
              />
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default PrsScreen;
