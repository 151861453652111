import React, { createContext, useState, useEffect } from "react"
import axios from 'axios';
import { useToken } from "./TokenContext";
import { useSelector } from "react-redux";

export const SimContext = createContext({
  usagearray:[],
  simCardStatus:{
    offlineActive: 0,
    onlineActive: 0,
    offlineInactive: 0
  },
  eid: [],
  eid32: [], //NIEUW
  msisdn: [], //NIEUW
  status: [],
  connection: [],
  simId: [],
  activeSims: [],
  inactiveSims: [],
  dataType: ' ',
  locationData: [],
  poolUsage: [],
  connectionHistory: [],
  networkEvents: [],
  accountId: '',
  offers: [],
  activatedDate: [],

  //NIEUW -> Olav
  activeProductInstanceId: [],
  apn: [],
  apnPassword: [],
  apnUsername: [],
  billingAccountId: [],
  billingAccountName: [],
  cardType: [],
  companyName: [],
// dataAuthorizeImei:false
// dataEnabled:true
// dataLimitEnabled: false
  iccid: [],
  id: [],
  imsi: [],
  ipAddress: [],
  // isSharedPool:  false
  name: [],
  netmask: [],
  // networkStatus:  "OFFLINE"
  offerNameSnapshot: [], // :  "LMobi 300MB Pool"
  opcKey: [], //:  "0"
  otaKey: [],//  :  "0"
  // pending:  false
  pin1: [], // "0"
  pin2: [], //  "2886"
  plan: [],//  : {id: "ac1b0317-8e5d-1068-818e-7a8ed57905c7", name: "LMOBI_300MB", description: "", currency: "EUR",…}
  planName: [],//  "LMOBI_300MB"
  primaryDns: [],//  "8.8.8.8"
  product: [],//  {id: "0b000064-8983-17b1-8189-b1ab38e85601", name: "Global IoT Normal",…}
  productInstanceParameters: [],// [{name: "SVC_SMS_MO_BLOCKED", value: "N", type: "NETWORK_ENTITLEMENTS",…},…]
  productName: [],//  "Global IoT Normal"
  puk1: [],// "12417408"
  puk2: [], // "56956449"
  quotaKB: [],//  10240
  reason: [],//  "ON_CUSTOMER_REQUEST"
  secondaryDns: [],//  "8.8.4.4"
  // securityEnabled:  false
  sellerId: [],//(INTERESSANT)   "ed58c7c9-7328-4e5e-9653-3792c0b307db"
  simPoolId: [],//  "ac1b0317-8e5d-1068-818e-7acc2aa508d3"
  simPoolName: [],//  "L-Mobi 300MB Test Data Pool 03-2024"
  smsEnabled: [],//  true
  status: [],//  "ACTIVE"
  tags: [], //(HEEL INTERESSANT) ["L-Mobi"]
  reloadKey: 0

  })

export const SimProvider = ({ children }) => {
  const [usageArray, setUsageArray] = useState([])
  const [simIdIndividual, setSimIdIndividual] = useState(''); 
  const [startDateIndividual, setStartDateIndividual] = useState(''); 
  const [startDateNetwork, setStartDateNetwork] = useState(''); 

  const [simCardStatus, setSimCardStatus] = useState({
    offlineActive: 0,
    onlineActive: 0,
    offlineInactive: 0 
  });
  const [simCardsData, setSimCardsData] = useState([]);
  const [eid, setEid] = useState([]);
  const [status, setStatus] = useState([]);
  const [connection, setConnection] = useState([]);
  const [simId, setSimId] = useState([]);
  const [reloadKey, setReloadKey] = useState(0)

  const [allSims, setAllSims] = useState([]);


  const [poolsData, setPoolsData] = useState([]);

  const [dataType, setDataType] = useState(' ')

  const [locationData, setLocationData] = useState([])

  const [poolId, setPoolId] = useState(''); 
  const [poolUsage, setPoolUsage] = useState([])

  const [connectionHistory, setConnectionHistory] = useState([])

  const [networkEvents, setNetworkEvents] = useState([])
  const [accountId, setAccountId] = useState('')
  const [offers, setOffers] = useState([])

  

  const { user } = useSelector((state) => state.user);

  
  const accessToken = useToken()
  useEffect(() => {
    if (!user || !accessToken.token || !simIdIndividual.length === 36 || !startDateIndividual.trim()) return;
  
  
    let url;
    if (dataType === 'individual') {
      url = `https://portal.oneworldnumber.com/api/simcard/${simIdIndividual}/usage?eventType=MODATA&startDate=${startDateIndividual}&timezone=0&isCost=false`;
    } else if (dataType === 'pooled') {
      url = `https://portal.oneworldnumber.com/api/simcard/${simIdIndividual}/user-account-usage?eventType=MODATA&startDate=${startDateIndividual}&timezone=0&isCost=false`;
    } else {
      return; 
    }
  
    axios.get(url, { headers: { 'Authorization': `Bearer ${accessToken.token}` } })
      .then(response => {
        setUsageArray(response.data);
      })
      .catch(error => {
        console.error(`Error fetching ${dataType} data:`, error);
      });
  }, [accessToken.token, simIdIndividual, startDateIndividual, dataType, user]);


  const fetchSimCardData = async (networkStatus, status) => {
    const url = `https://portal.oneworldnumber.com/api/simcard/count?networkStatus.equals=${networkStatus}&status.equals=${status}`;
    // const url = `https://qa-portal.simfony.rocks/api/simcard/count?networkStatus.equals=${networkStatus}&status.equals=${status}`;
    try {
      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${accessToken.token}`,
        },
      });
     
      return response.data;
    } catch (error) {
      console.error(`Error fetching ${networkStatus} ${status} data:`, error);
      return 0; // Return an empty array or any default value in case of an error
    }
  };
  useEffect(() => {
    if (!user || !accessToken.token) return; // Ensure accessToken is available

    const combinations = [
      { networkStatus: 'OFFLINE', status: 'ACTIVE' },
      { networkStatus: 'ONLINE', status: 'ACTIVE' },
      { networkStatus: 'OFFLINE', status: 'INACTIVE' },
      { networkStatus: 'ONLINE', status: 'INACTIVE' }
    ];

    const fetchAllSimCardData = async () => {
      const dataPromises = combinations.map(async ({ networkStatus, status }) => {
        const data = await fetchSimCardData(networkStatus, status);
        const key = `${networkStatus.toLowerCase()}${status.charAt(0).toUpperCase()}${status.slice(1).toLowerCase()}`;
        return { key, data };
      });

      const results = await Promise.all(dataPromises);

      setSimCardStatus(prevState => {
        const newState = { ...prevState };
        results.forEach(({ key, data }) => {
          newState[key] = data;
        });
        return newState;
      });
    };

    fetchAllSimCardData();
  }, [accessToken.token, user]);

  useEffect(() => {
    if (!user || !accessToken.token) return; 

    const fetchData = async () => {
      try {
        const response = await axios.get('https://portal.oneworldnumber.com/api/simcard?status.in=ACTIVE&status.in=INACTIVE&page=0&size=20' , {
          headers: {
            'Authorization': `Bearer ${accessToken.token}`,
          },
        });

        setSimCardsData(response.data);
      } catch (error) {
        console.error('Error fetching additional data:', error);
      }
    };

    fetchData();
  }, [accessToken.token, user, reloadKey]);

  useEffect(() => {
    const newEids = simCardsData.map(sim => sim.eid32);
    const newStatuses = simCardsData.map(sim => sim.status);
    const newConnections = simCardsData.map(sim => sim.networkStatus);
    const newSimIds = simCardsData.map(sim => sim.id)
    const allSims = simCardsData.reduce((acc, sim) => {
      acc.push({
        eid: sim.eid32,
        status: sim.status,
        simId: sim.id,
        msisdn: sim.msisdn,
        planName: sim.planName,
        activatedDate: sim.activatedDate,
        offer: sim.offerNameSnapshot,
        tags: sim.tags
      });
      return acc;
    }, []);
    // Update state with the new values
    setEid(newEids);
    setStatus(newStatuses);
    setConnection(newConnections);
    setSimId(newSimIds)
    setAllSims(allSims)
  }, [simCardsData]); // This effect depends on simCardsData
  useEffect(() => {
    if (!user || !(accessToken.token)) return; 

    const fetchData = async () => {
      try {
        const response = await axios.get('https://portal.oneworldnumber.com/api/sim-pool?status.in=ACTIVE&status.in=INACTIVE&page=0&size=20', {
          headers: {
            'Authorization': `Bearer ${accessToken.token}`,
          },
        });
        const processedPools = response.data.map(pool => ({
          name: pool.name,
          planName: pool.plan.informationFormGroup.name,
          numOfSims: pool.simCards.length,
          poolType: pool.poolType,
          simCards: pool.simCards,
          poolId: pool.id
        }));
        setPoolsData(processedPools);
      } catch (error) {
        console.error('Error fetching additional data:', error);
      }
    };
    fetchData();
  }, [accessToken.token]);

  
  useEffect(() => {
    if (!user || !accessToken.token) return; 

    const fetchData = async () => {
      try {
        const response = await axios.get('https://portal.oneworldnumber.com/api/simcard/location', {
          headers: {
            'Authorization': `Bearer ${accessToken.token}`,
          },
        });
       setLocationData(response.data)
      } catch (error) {
        console.error('Error fetching additional data:', error);
      }
    };
    fetchData();
  }, [accessToken.token, user]);

    
  useEffect(() => {
    if (!user || !accessToken.token | !poolId) return;
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://portal.oneworldnumber.com/api/sim-pool/${poolId}/with-usage`, {
          headers: {
            'Authorization': `Bearer ${accessToken.token}`,
          },
        });
       setPoolUsage(response.data)
      } catch (error) {
        console.error('Error fetching additional data:', error);
      }
    };
    fetchData();
  }, [accessToken.token, user]);

  useEffect(() => {
    if (!user || !accessToken.token) return;
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://portal.oneworldnumber.com/api/sim-card-status-overtime/me`, {
          headers: {
            'Authorization': `Bearer ${accessToken.token}`,
          },
        });
       setConnectionHistory(response.data)
      } catch (error) {
        console.error('Error fetching additional data:', error);
      }
    };
    fetchData();
  }, [accessToken.token, user]);

  useEffect(() => {
    if (!user || !accessToken.token || !startDateNetwork ) return;
    const fetchData = async () => {
      const now = new Date();
      const then = new Date(startDateNetwork)
      const currentDate = now.toISOString();
      const formattedStartDateNetwork = then.toISOString();
      try {
        const response = await axios.get(`https://portal.oneworldnumber.com/api/network-event/group-by-field?field=textk_event_name&date_timestamp.greaterThanOrEqual=${formattedStartDateNetwork}&date_timestamp.lessThanOrEqual=${currentDate}`, {
          headers: {
            'Authorization': `Bearer ${accessToken.token}`,
          },
        });
       setNetworkEvents(response.data)
      } catch (error) {
        console.error('Error fetching additional data:', error);
      }
    };
    fetchData();
  }, [accessToken.token, user, startDateNetwork]);

  useEffect(() => {
    if (!user || !accessToken.token) return;

    const fetchData = async () => {
      try {
        const accountResponse = await axios.get('https://portal.oneworldnumber.com/api/me', {
          headers: {
            'Authorization': `Bearer ${accessToken.token}`,
          },
        });
        const accountId = accountResponse.data.accountId;
        setAccountId(accountId); 

       
        if (accountId) {
          const offersResponse = await axios.get(`https://portal.oneworldnumber.com/api/offer?accountId.equals=${accountId}&page=0&size=10`, {
            headers: {
              'Authorization': `Bearer ${accessToken.token}`,
            },
          });
          setOffers(offersResponse.data) 
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [accessToken.token, user, reloadKey])
  
  const activateSim = async (simId) => { //NIEUW
    try {
        const requestBody = {
            "simcardIds": [simId],
            "status": "ACTIVE",
            "reason": "ON_CUSTOMER_REQUEST"
        };

        console.log('Request body:', JSON.stringify(requestBody));

        const response = await fetch('https://portal.oneworldnumber.com/api/order/sim-card/action', {
            method: 'POST',
            headers: {
               'Authorization': `Bearer ${accessToken.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody), 
        });
   
        if (!response.ok) {
            throw new Error('Failed to activate SIM');
        }

        return await response.json();  
    } catch (error) {
        console.error('Error activating SIM:', error);
        throw error;
    }
};


const changeOffer = async (offerId, simcardId) => { 
  try {
      let currentDate = new Date();
      let requestedStartDate = currentDate.toISOString();
      const offerBody = {
        "offerId": offerId,
        "autoRenewal":false,
        "pool":null,
        "requestedStartDate": requestedStartDate,
        "simcardIds":[simcardId]
      };

      console.log('Request body:', JSON.stringify(offerBody));

      const response = await fetch('https://portal.oneworldnumber.com/api/order/sim-card/change-plan', {
          method: 'PUT',
          headers: {
             'Authorization': `Bearer ${accessToken.token}`,
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(offerBody), 
      });
 
      if (!response.ok) {
          throw new Error('Failed to change offer');
      }

      return await response;  
  } catch (error) {
      console.error('Error changing offer:', error);
      throw error;
  }
};

const changeToNoOffer = async (offerId, simcardId, duration) => { 
  try {
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + duration);
    let requestedStartDate = currentDate.toISOString();
    const noOfferId = 'ac1b0317-8ebc-18a4-818e-eb6d039a474d'

      const offerBody = {
        "offerId": noOfferId,
        "autoRenewal":false,
        "pool":null,
        "requestedStartDate": requestedStartDate,
        "simcardIds":[simcardId]
      };

      console.log('Request body:', JSON.stringify(offerBody));

      const response = await fetch('https://portal.oneworldnumber.com/api/order/sim-card/change-plan', {
          method: 'PUT',
          headers: {
             'Authorization': `Bearer ${accessToken.token}`,
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(offerBody), 
      });
 
      if (!response.ok) {
          throw new Error('Failed to change offer');
      }

      return await response;  
  } catch (error) {
      console.error('Error changing offer:', error);
      throw error;
  }
};
const resetSim = async (simId) => {
  try {
      const response = await fetch(`https://portal.oneworldnumber.com/api/simcard/location-reset/${simId}/CS`, {
          method: 'GET',
          headers: {
             'Authorization': `Bearer ${accessToken.token}`,
              'Content-Type': 'application/json',
          }
      });
 
      if (!response.ok) {
          throw new Error('Failed to reset SIM');
      }

      return await response 
  } catch (error) {
      console.error('Error reset SIM:', error);
      throw error;
  }
}; 
const addTag = async (simId, newTags) => { 
  try {
      const requestBody = simCardsData.find(sim => sim.id === simId)
      const tagsToAdd = Array.isArray(newTags) ? newTags : [newTags];
      tagsToAdd.forEach(tag => {
        requestBody.tags.push(tag);
      });
      
      console.log('Request body:', JSON.stringify(requestBody));

      const response = await fetch('https://portal.oneworldnumber.com/api/simcard/collection', {
          method: 'PUT',
          headers: {
             'Authorization': `Bearer ${accessToken.token}`,
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody), 
      });
 
      if (!response.ok) {
          throw new Error('Failed to add tag');
      }
      console.log('Tag added successfully');
      return await response
  } catch (error) {
      console.error('Error adding tag:', error);
      throw error;
  }
};
const removeTag = async (simId, removedTag) => { 
  try {
      const requestBody = simCardsData.find(sim => sim.id === simId)
      const index = requestBody.tags.indexOf(removedTag);

      if (index > -1) {
          requestBody.tags.splice(index, 1);
      } else {
          console.log('Tag not found:', removedTag);
          return;  
      }
      console.log(requestBody.tags)
    
      const response = await fetch('https://portal.oneworldnumber.com/api/simcard/collection', {
          method: 'PUT',
          headers: {
             'Authorization': `Bearer ${accessToken.token}`,
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody), 
      });
 
      if (!response.ok) {
          throw new Error('Failed to activate SIM');
      }
      console.log('Tag removed successfully');
      return await response
  } catch (error) {
      console.error('Error activating SIM:', error);
      throw error;
  }
};
const sellSim = async (simId, offerId, duration, infoTag) => {
  try {
      const activationResult = await activateSim(simId);
      console.log('SIM activation successful:', activationResult);

      const changeOfferResult = await changeOffer(offerId, simId);
      console.log('Offer change successful:', changeOfferResult);

      const noOfferResult = await changeToNoOffer(offerId, simId, duration)
      console.log('No offer scheduled appropriately', noOfferResult)

      const resetResult = await resetSim(simId)
      console.log('Sim reset successfully', resetResult)

      const addedSoldTagResult = await addTag(simId, 'Sold') 
      console.log('Sold tag added succesfully', addedSoldTagResult)

      const addedInfoTagResult = await addTag(simId, infoTag) 
      console.log('Info tag added succesfully', addedInfoTagResult)
      return {
          activation: activationResult,
          offerChange: changeOfferResult,
          noOffer: noOfferResult,
          addSoldTag: addedSoldTagResult,
          addInfotag: addedInfoTagResult
      };
  } catch (error) {
      console.error('Error selling SIM card:', error);
      throw error;  // Propagate the error upwards
  }
};

const simToInv = async (simId) => {
  try {
      const verificationResult = await verifySim(simId)
      console.log('SIM verification successfull:', verificationResult)
      const verificationResult2 = await verifySim(simId)
      console.log('SIM verification successfull:', verificationResult2)
      const deActivationResult = await deactivateSim(simId);
      console.log('SIM deactivation successful:', deActivationResult);
      const removedTagResult = await removeTag(simId, 'Sold') // sale tag opstellen
      console.log('Sale removed added succesfully', removedTagResult)
      // const noOfferId = 'ac1b0317-8ebc-18a4-818e-eb6d039a474d'
      // const changeOfferResult = await changeOffer(noOfferId, simId);
      // console.log('Offer change successful:', changeOfferResult);

      // Return results of both operations
      return {
          verification: verificationResult,
          activation: deActivationResult,
          // offerChange: changeOfferResult
          removal: removedTagResult
      };
  } catch (error) {
      console.error('Error selling SIM card:', error);
      throw error;  // Propagate the error upwards
  }
};


const deactivateSim = async (simId) => {
  try {
      const requestBody = {
          "simcardIds": [simId],
          "status": "INACTIVE",
          "reason": "ON_CUSTOMER_REQUEST"
      };

      console.log('Request body:', JSON.stringify(requestBody));

      const response = await fetch('https://portal.oneworldnumber.com/api/order/sim-card/action', {
          method: 'POST',
          headers: {
             'Authorization': `Bearer ${accessToken.token}`,
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody), 
      });
 
      if (!response.ok) {
          throw new Error('Failed to deactivate SIM');
      }

      return await response.json();  
  } catch (error) {
      console.error('Error deactivating SIM:', error);
      throw error;
  }
};

const verifySim = async (simId) => {
  try {
      const requestBody = {
          "newSimReason": "ON_CUSTOMER_REQUEST",
          "newStatus": "INACTIVE",
          "simcardIds": [simId],
      };

      console.log('Request body:', JSON.stringify(requestBody));

      const response = await fetch('https://portal.oneworldnumber.com/api/simcard/status/verify', {
          method: 'POST',
          headers: {
             'Authorization': `Bearer ${accessToken.token}`,
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody), 
      });
 
      if (!response.ok) {
          throw new Error('Failed to verify SIM');
      }

      return await response.json();  
  } catch (error) {
      console.error('Error verifying SIM:', error);
      throw error;
  }
};

const createSubUser = async (email, name, phone, surname) => {
  try {
      const billingResponse = await axios.get(`https://portal.oneworldnumber.com/api/customer-profile/billing-accounts/${accountId}`, {
              headers: {
              'Authorization': `Bearer ${accessToken.token}`,
              },
          });
          const billingId = billingResponse.data[0].code

  
      const requestBody = {
          "billingAccountCode": billingId,
          "email": email,
          "factoryType": "email",
          "name": name,
          "permissionGroup": "ROLE_CUSTOMER_ADMIN",
          "phoneNumber": phone,
          "surname": surname,
          "emailReceiver": false
      }

      console.log('Request body:', JSON.stringify(requestBody));

      const response = await fetch(`https://portal.oneworldnumber.com/api/customer-profile/user/${accountId}`, {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${accessToken.token}`,
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody), 
      });
      console.log(response.error)
      if (!response.ok) {
          throw new Error('Failed to create new user');
      }
          console.log('Added new user', response) 
          return await response.json(); 
} catch (error) {
    console.error('Error creating user:', error);
    throw error;
}
};



  return (
    <SimContext.Provider value={{ simIdIndividual, startDateIndividual, usageArray, 
      setSimIdIndividual, setStartDateIndividual, simCardStatus,  eid, poolsData,
      status,connection, simId, dataType, setDataType, locationData, setLocationData, 
      poolId, setPoolId, poolUsage, setPoolUsage, connectionHistory, networkEvents, 
      allSims, accountId, offers, activateSim, deactivateSim, sellSim, simToInv, addTag, removeTag, reloadKey, setReloadKey, createSubUser, startDateNetwork, setStartDateNetwork}}>
      {children}
    </SimContext.Provider>
  )
}
