import { Outlet } from 'react-router-dom';
import './layout.styles.scss';

const MainLayout = () => (
  <div>
    <main>
      <Outlet />
    </main>
  </div>
);

export default MainLayout;
