import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { getTiles } from './api/apiFunctions';
import './App.scss';

import { setDefaultServices } from './redux/defaultServicesSlice';
import { ProtectedRoute } from './routes/protected-route.component';
import SigninAndSignup from './screens/signin-and-signup/signin-and-signup.component.jsx';
import HeadingPrimary from './components/typography/headings/heading-primary/heading-primary.component';
import { useTheme } from './ThemeContext.jsx';
import RoleBasedLayout from './components/layouts/rolebasedlayout.component.jsx';
import Homescreen from '../../client/src/screens/homescreen/homescreen.component.jsx';
import OverlayContainer from './components/layouts/backdrop/overlay.component.jsx';
import FirstLoginScreen from './screens/firstloginscreen/firstloginscreen.component.jsx';
import { AuthProvider } from './AuthContext';
import CreateAcccountScreen from './screens/createaccountscreen/create-accountscreen.component.jsx';
function App() {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userColor = useSelector((state) => state.theme.color);

  useEffect(() => {
    if (user) {
      getTiles().then((res) => dispatch(setDefaultServices(res)));
      navigate('/home');
    }
  }, [user]);
  const { theme } = useTheme();
  useEffect(() => {
    document.documentElement.style.setProperty('--dynamic-color', userColor);   
  }, [userColor]);
  

  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
  }, [theme]);  

  return (
    <AuthProvider>
<div className="App" data-theme={theme}>
  <OverlayContainer/>
    <Routes>
      <Route path="/" element={<SigninAndSignup/>} />
      <Route path="/signin" element={<SigninAndSignup />} />
      <Route path="/first-login" element={<FirstLoginScreen />} />
      <Route path="/create-account" element={<CreateAcccountScreen />} />
      <Route element={<ProtectedRoute user={user} />}>
        {user && (
          <Route
            path="/*"
            element={<RoleBasedLayout role={user[7]} />}
          />
        )}
      </Route>

      {/* Route for handling undefined paths */}
      <Route path="*" element={<Homescreen/>} />
    </Routes>
</div>
</AuthProvider>
  );
}

export default App;
