import './profileinformation.styles.scss';
import InputField from '../../../../components/input-field/inputfield.component';
import { useState } from 'react';
import ReactFlagsSelect from "react-flags-select";

const ProfileInformation = () => {
  const [selected, setSelected] = useState("");
  return (
    <div className='col-2'>
    <div className='col-2-container'>
      <div className='col-2-row-1'>
        <div className='col-2-heading'> Profile Information</div>
        <InputField label='Full Name' placeholder='e.g. John' id='fullName'/>
        <InputField label='Email Address' placeholder='e.g. john@doe.com' id='emailpersonal'/>
        <div className='phone-input'>
        <InputField label='Phone' placeholder='e.g. +31 (1) 23456789' id='phonepersonal'/>
       
           {/* <ReactFlagsSelect
    selected={selected}
    onSelect={(code) => setSelected(code)}
    placeholder=' '
    className='flag'
    showOptionLabel={false}
    showSelectedLabel={false}
    fullWidth={false}

  /> */}
  </div>
        
      </div>
      <div className='col-2-row-2'>
        <div className='col-2-heading'> Billing Adress</div>
        <div className='input-container'>
          <div className='row-2-input-container'>
            <InputField label='Address' placeholder='e.g. Boeingavenue 8' id='address'/>
            <InputField label='Zip code' placeholder='e.g. 1119PB' id='zipcode'/>
            <InputField label='Country' placeholder='e.g. Netherlands' id='country'/>
          </div>
        </div>
      </div>
      <div className='col-2-row-3'> 
        <div className='col-2-heading'>Contact Information</div>
          <div className='row-3-input-container'>
            <InputField label='Email' placeholder='e.g. john@doe.com' id='email'/>
            <InputField label='Phone Number' placeholder='e.g. +31 (1) 23456789' id='email'/>
          </div>
      </div>
      <div className='col-2-row-4'>
        <button className='discard'>Discard</button>
        <button className='saveChanges'>Save Changes</button>
      </div>
    </div>
    </div>
  );
}

export default ProfileInformation;
