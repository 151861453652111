import React, { useState, useEffect, useContext } from "react"
import '../screens/athalosscreen/athalosscreen.styles.scss'
import { DataContext } from "./DataContext"

function TotalCallsGas({ className, selectedRange, displayMode }) {
  const [totalCallsAnswered, setTotalCallsAnswered] = useState(null)
  const [totalCalls, setTotalCalls] = useState(null)
  const { records, dailyrecords } = useContext(DataContext)

  useEffect(() => {
    if (records && records.length > 0) {
      let totalansweredCalls = 0
      let totalCalls = 0
      let filteredrecords

      if (selectedRange === "Today") {
        filteredrecords = dailyrecords
      } else if (selectedRange === "Last 7 days") {
        filteredrecords = records.slice(0, 7)
      } else if (selectedRange === "Last Month") {
        filteredrecords = records
      } else {
        filteredrecords = dailyrecords
      }

      filteredrecords.forEach(record => {
        const parts = record.split(">>")
        parts.forEach(part => {
          const [key, value] = part.split("=")
          if (key === "callsa") {
            totalansweredCalls += parseInt(value, 10)
          }
          if (key === "calls") {
            totalCalls += parseInt(value, 10)
          }
        })
      })

      setTotalCallsAnswered(totalansweredCalls)
      setTotalCalls(totalCalls)
    }
  }, [selectedRange, records])

  return (
    <div className={className}>
      {(displayMode === 'first' || displayMode === 'both') && (
        <div >{totalCallsAnswered !== null ? totalCallsAnswered : 0}</div>
      )}
      {(displayMode === 'second' || displayMode === 'both') && (
        <div>
        {totalCalls !== null ? totalCalls : 0}
      </div>
      )}
    </div>
  )
}

export default TotalCallsGas
