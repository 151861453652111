import React, {useState, useRef} from 'react';
import { Link } from 'react-router-dom';
import { IoIosRedo } from "react-icons/io";
import HeadingPrimary from '../../components/typography/headings/heading-primary/heading-primary.component';
import HeadingSecondary from '../../components/typography/headings/heading-secondary/heading-secondary.component';
import Sidebar from '../../components/sidebar/sidebar.component';
import Arrow from '../../assets/img/purple_arrow_down.png'
import './ownscreen.styles.scss';
import { useSelector } from 'react-redux';
import HeaderComponent from '../../components/header-component/header.component';


const OwnScreen = () => {
  const showSidebar = useSelector((state) => state.ui.sidebarOpen)
  const isMobile = window.innerWidth <= 766;  
  const [showSidebarMobile, setShowSidebarMobile] = useState(false);
  return (
    <div className='header-layout'>
      <div className="bottom-nav-container">
        <HeaderComponent showSidebarMobile={showSidebarMobile}
        setShowSidebarMobile={setShowSidebarMobile} />
      </div>
      <div className='portalscreen-layout'>
      {isMobile ? (
        <div className={`sidebar-container-mobile ${showSidebarMobile ? 'sidebar-visible' : ''}`}>
          <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
            <Sidebar />
          </div>
        </div>
      ) : (
        <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
          <Sidebar />
        </div>
      )}
          <div className={'content-container'}>
              <div className="portal-container">
                <HeadingSecondary text={'OWN KPI Portal'} />
              <div className="sub-container">
              <iframe
                title="Embedded Website"
                src="https://manage.wix.com/dashboard/76b810df-9e17-4292-9547-f420df85f007/analytics/overviews/traffic"
                width="100%"
                height="1300px"
                className='prs-iframe'
              />
              </div>
              </div>
            </div>
          </div>
        </div>
  );
};

export default OwnScreen;