import './form-input.styled.scss';

const FormInput = ({ handleChange, label, error, ...props }) => (
  <div className="form-input">
    <input
      className="form-input-input"
      onChange={handleChange}
      style={{ borderColor: error && '#D30D52' }}
      {...props}
    />
    <label className="form-input-label">{label}</label>
  </div>
);

export default FormInput;
