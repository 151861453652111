import { useState, useEffect, useContext } from 'react';
import HeadingPrimary from '../../components/typography/headings/heading-primary/heading-primary.component';
import { useAuth } from '../../AuthContext';
import { SimContext } from '../../api/SimContext';
import { signin } from '../../api/apiFunctions.js';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../redux/userSlice.js';
import { createDatabaseUser } from '../../api/apiFunctions.js';
import './firstlogin.styles.scss'

const FirstLoginScreen = () => {
  const [loadingText, setLoadingText] = useState('Getting your SIMs ready');
  const loadingMessages = ['Setting up your account', 'Generic loading message', 'Making Connections', 'Inspirational quote'];
  const { authState } = useAuth();
  const { username, password } = authState;
  const [errMsg, setErrMsg] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoadingText((prevText) => {
        const currentIndex = loadingMessages.indexOf(prevText);
        const nextIndex = (currentIndex + 1) % loadingMessages.length;
        return loadingMessages[nextIndex];
      });
    }, 1700);

    return () => clearInterval(intervalId);
  }, []);


  const handleFirstLogin = async () => {
    try {
      // Create the database user
      await createDatabaseUser(username, password, 'newUser');
      console.log('User created successfully');

      // Perform the sign-in request
      const user = await signin(username, password, setErrMsg);
      if (user) {
        dispatch(loginUser(user));
        navigate('/home');
      } else {
        navigate('/first-login');
      }
    } catch (error) {
      console.error('Error during first login process:', error);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleFirstLogin();
    }, 4300); // Wait for 5 seconds before running handleFirstLogin

    return () => clearTimeout(timeoutId); // Cleanup the timeout if the component unmounts
  }, []); // Empty dependency array ensures it runs only once

  return (
    <div className='header-layout'>
    <div className='portalscreen-layout'>
     <div className={'firstlogin-container'}>
        <HeadingPrimary text={'Welcome to the OWN Portal!'} mode={'dark'} />
        <div className='loading-text'>{loadingText}</div>
        <div className='login-loader'></div>
          <p>{errMsg}</p>
      </div>
    </div>
    </div>
  );
};

export default FirstLoginScreen;