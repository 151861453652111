import { MdFeedback, MdLocalGroceryStore, MdAreaChart, MdExplore, MdSimCard, MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { MdOutlineRocketLaunch } from "react-icons/md";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { GiMoebiusStar } from "react-icons/gi";
import { TbMobiledata } from "react-icons/tb";
import { ImConnection } from "react-icons/im";
import { FaInfo } from 'react-icons/fa';
import { IoIosText, IoIosSettings } from 'react-icons/io';
import { BsFillLaptopFill } from "react-icons/bs";
import { MdSms } from "react-icons/md";
import { MdDashboard } from "react-icons/md";
import { MdInsertChartOutlined } from "react-icons/md";
import { MdCall } from "react-icons/md";
import { MdStorefront } from "react-icons/md";
import { MdOutlineSimCardDownload } from "react-icons/md";
import { MdWidgets } from "react-icons/md";
import { MdOutlineStorage } from "react-icons/md";



export const admin = [
  {
    title: 'Explore',
    path: '/home',
    icon: <MdExplore size={28}/>,
    cName: 'nav-item',
  },
  {
    title: 'Reseller',
    path: '/reseller',
    icon: <MdStorefront size={28}/>,
    cName: 'nav-item',
  
  },
  {
    title: 'Sim Overview',
    icon: <MdSimCard size={28}/>,
    cName: 'nav-item',
    toggle: <MdOutlineKeyboardArrowLeft size={28}/>,
    subNav: [
          {
            title: 'Overview',
            path: '/overview',
            cName: 'nav-item-submenu',
          },
          {
            title: 'Manage Sims',
            path: '/manage',
            cName: 'nav-item-submenu',
          }
        
        
        
        ]
  },
  {
    title: 'KPI-Portal',
    icon: <MdAreaChart size={28} />,
    cName: 'nav-item',
    toggle: <MdOutlineKeyboardArrowLeft size={28} />, 
    subNav: [
      {
        title: 'Athalos',
        path: '/portal-athalos',
        cName: 'nav-item-submenu',
      },
      {
        title: 'OWN',
        path: '/portal-own',
        cName: 'nav-item-submenu',
      },
      {
        title: 'Prs',
        path: '/portal-prs',
        cName: 'nav-item-submenu',
      },
    ],
  },
  {
    title: 'Value Added Services',
    path: '/value-added-services',
    icon: <BsFillLaptopFill size={28}/>,
    cName: 'nav-item',
  },
  
  {
    title: 'Messages',
    path: '/messages',
    icon: <MdSms size={28} />,
    cName: 'nav-item',
  },
  {
    title: 'VoiP',
    path: '/voip',
    icon: <MdCall size={28} />,
    cName: 'nav-item',
  }
  
];


export const reseller = [
  {
    title: 'Explore',
    path: '/home',
    icon: <MdExplore size={28}/>,
    cName: 'nav-item',
  },
  {
    title: 'Reseller',
    path: '/reseller',
    icon: <MdStorefront size={28}/>,
    cName: 'nav-item',
  
  },
  {
    title: 'Value Added Services',
    path: '/value-added-services',
    icon: <BsFillLaptopFill size={28}/>,
    cName: 'nav-item',
  },
]
export const TT = [
  {
    title: 'Explore',
    path: '/home',
    icon: <MdExplore size={28}/>,
    cName: 'nav-item',
  },
  {
    title: 'Reseller',
    path: '/reseller',
    icon: <MdStorefront size={28}/>,
    cName: 'nav-item',
  
  },
  {
    title: 'Value Added Services',
    path: '/value-added-services',
    icon: <BsFillLaptopFill size={28}/>,
    cName: 'nav-item',
  },
]






export const account = [ // DYNAMIC for Feedback// = (currentPage) => [
  // {
  //   title: 'Need support?',
  //   path: 'mailto:support@athalos.com?subject=Feedback About App', // ORIGINAL -> path:'/feedback' // DYNAMIC ${currentPage}`
  //   icon: <MdFeedback size={28}/>,
  //   cName: 'nav-item-settings'
  // },
  {
    title: 'Settings',
    path: '/settings',
    icon: <IoIosSettings size={28}/>,
    cName: 'nav-item-settings'
  }
];

export const newUser = [
  {
    title: 'Explore',
    path: '/home',
    icon: <MdExplore size={28}/>,
    cName: 'nav-item',
  }

]
