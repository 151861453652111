import React, { useContext, useState } from 'react';
import HeadingPrimary from '../../components/typography/headings/heading-primary/heading-primary.component';
import HeadingSecondary from '../../components/typography/headings/heading-secondary/heading-secondary.component';
import Sidebar from '../../components/sidebar/sidebar.component';
import './storescreen.styles.scss';
import { useSelector } from 'react-redux';
import HeaderComponent from '../../components/header-component/header.component';
const Storescreen = () => {
  const showSidebar = useSelector((state) => state.ui.sidebarOpen)
  const isMobile = window.innerWidth <= 766;
  const [showSidebarMobile, setShowSidebarMobile] = useState(false);
  return (
    <div className='header-layout'>
    <div className="bottom-nav-container">
      <HeaderComponent showSidebarMobile={showSidebarMobile}
      setShowSidebarMobile={setShowSidebarMobile} />
    </div>
    <div className='portalscreen-layout'>
    {isMobile ? (
      <div className={`sidebar-container-mobile ${showSidebarMobile ? 'sidebar-visible' : ''}`}>
        <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
          <Sidebar />
        </div>
      </div>
    ) : (
      <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
        <Sidebar />
      </div>
    )}
         <div className={'content-container'}>
        <HeadingPrimary text={'Store'} />
          <div className='store-container'>
            <HeadingSecondary text={'OWN Store'} />
            <div className="sub-container">
            <iframe
              title="Embedded Website"
              src="https://oneworldnumber.com/category/store-products"
              width="100%"
              height="1300px"
              className='store-iframe'
            />
            </div>
          </div>
        </div>
      </div>
      </div>
  );
};

export default Storescreen;
