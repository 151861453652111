import React from 'react';
import './inputfield.styles.scss'

const InputField = ({ placeholder, id,label }) => {
  return (
    <div className="input-container">
      <label htmlFor={id} className='input-label'>{label}</label>
      <input type="text" placeholder={placeholder} id={id} className="input-field" />
    </div>
  );
};

export default InputField;