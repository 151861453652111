import '../../../screens/homescreen/homescreen.styles.scss';

const ExploreComponent = ({ toggleModal }) => { 
  return (
        <div className='home-container'>
            <div className='own-tiles-container'>
                <div className='notif-block'>
                <div className='notif-text bold'>Add SIM(s)</div>
                <div className='notif-text'>Add your recently bought SIM(s) to your account</div>
                <div className='notif-button-container'>
                    <div className='notif-button' onClick={toggleModal}>Add now</div>
                </div>
                </div>
                <div className='notif-block blue'>
                <div className='notif-text bold'>Buy more SIMs</div>
                <div className='notif-text'>Add more SIM cards to your account</div>
                <div className='notif-button-container'>
                    <div className='notif-button'>Buy now</div>
                </div>
                </div>
                <div className='notif-block yellow'>
                <div className='notif-text bold'>Get VPN</div>
                <div className='notif-text'>Secure your SIM(s) with our premium VPN services</div>
                <div className='notif-button-container'>
                    <div className='notif-button'>Get now</div>
                </div>
                </div>
            </div>
        </div>
  );
};

export default ExploreComponent;
