import React from "react"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts"
import '../../screens/athalosscreen/athalosscreen.styles.scss'

const LineGraphGas = ({ data }) => {
  return (
    <ResponsiveContainer className='dynamic-graph'>
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 10,
          left: 10,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="day" />
        <YAxis yAxisId="left" orientation="left" stroke="#511b85" />
        <YAxis yAxisId="right" orientation="right" stroke="#8649bf" />
        <Tooltip offset={100} />
        <Legend />
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="calls"
          name="Total calls"
          stroke="#511b85"
          activeDot={{ r: 8 }}
          isAnimationActive={false}
        />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="durm"
          name="Minutes called"
          stroke="#8649bf"
          isAnimationActive={false}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default LineGraphGas
