import './resellerreportscreen.styles.scss';
import { useContext, useState, useEffect } from 'react';
import { SimContext } from '../../api/SimContext';
import HeadingSecondary from '../../components/typography/headings/heading-secondary/heading-secondary.component';
import { IoMdAddCircle } from 'react-icons/io';
import { IoReloadCircleSharp } from 'react-icons/io5';
import Modal from '../../components/modal/Modal';
import BasicForm from '../../components/userform/userform.component';
import * as XLSX from 'xlsx';
import { RiFileDownloadFill } from "react-icons/ri";

const ResellerReportScreen = () => {
  const { allSims, offers, reloadKey, setReloadKey } = useContext(SimContext);
  const [isModalShowing, setIsModalShowing] = useState(false);
  const getLast30DaysDate = () => {
    const date = new Date();
    date.setDate(date.getDate() - 29);
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };

  const [resellerData, setResellerData] = useState({});
  const [startDate, setStartDate] = useState(getLast30DaysDate);
  const [daysRange, setDaysRange] = useState("");
  const [isSpinning, setIsSpinning] = useState(false);

  const handleReload = () => {
    setIsSpinning(true);
    setTimeout(() => {
      setReloadKey(reloadKey + 1);
      setIsSpinning(false);
    }, 1000); // Match this duration with the CSS animation duration
  };

  const toggleModal = () => {
    setIsModalShowing(!isModalShowing);
  };


  const handleFormSubmit = (formData) => {
    console.log('Form Data Submitted:', formData);
    toggleModal();
  };

  useEffect(() => {
    if (!Array.isArray(allSims)) {
      console.error('allSims is not an array:', allSims);
      return;
    }

    console.log('Processing allSims:', allSims);

    const resellerData = {};

    const start = new Date(startDate);
    const now = new Date();

    // Calculate the difference in days between the selected start date and now
    if (startDate) {
      const timeDiff = Math.abs(now.getTime() - start.getTime());
      const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
      setDaysRange(dayDiff);
    }

    allSims.forEach(sim => {
      if (!Array.isArray(sim.tags)) {
        console.warn('Skipping sim with invalid tags:', sim);
        return;
      }

      const salesData = {};

      sim.tags.forEach(tag => {
        const match = tag.match(/(reseller|duration|offer|activatedDate)=([^#]+)#index=(\d+)/);
        if (match) {
          const [, key, value, index] = match;
          if (!salesData[index]) {
            salesData[index] = {};
          }
          salesData[index][key] = decodeURIComponent(value.replace(/\+/g, ' '));
        }
      });

      Object.values(salesData).forEach(sale => {
        const { reseller, offer, activatedDate } = sale;
        if (!reseller || !offer || !activatedDate) return;

        const saleDate = new Date(activatedDate);
        if (saleDate >= start && saleDate <= now) {
          if (!resellerData[reseller]) {
            resellerData[reseller] = {};
          }

          if (!resellerData[reseller][offer]) {
            resellerData[reseller][offer] = 0;
          }

          resellerData[reseller][offer] += 1;
        }
      });
    });

    console.log('Final resellerData:', resellerData);

    setResellerData(resellerData);
  }, [allSims, startDate]);

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      Object.entries(resellerData).map(([reseller, bundles]) => {
        const row = { reseller };
        offers.forEach(offer => {
          row[offer.name] = bundles[offer.name] || 0;
        });
        return row;
      })
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reseller Report");
    XLSX.writeFile(wb, "reseller_report.xlsx");
  };

  return (
    <div className={'content-container-component'}>
      <div className="messages-container">
        <div className='table-heading-container'>
          <div className='range-container'>
            <IoReloadCircleSharp size={28} color='black' onClick={handleReload} style={{ cursor: 'pointer'}} className={`reload-div ${isSpinning ? 'spin-animation' : ''}`} />
            <IoMdAddCircle size={28} color='black' onClick={toggleModal} style={{ cursor: 'pointer' }} />
            <RiFileDownloadFill  onClick={exportToExcel} size={28} color='black' style={{ cursor: 'pointer' }}/>
          </div>
          <div className='range-container'>
            {startDate && <div className='sim-text-bold'>Last {daysRange} days</div>}
            <div className='icon-container'>
              <input
                type="date"
                id="startDateIndividual"
                value={startDate}
                onChange={handleStartDateChange}
              />
            </div>
          </div>
        </div>
        <div className='panel-small'>
          <div className='panel-heading'>
            <div className='inner-panel-heading-products'>
              <div className='header-container-reseller-report'>
                <HeadingSecondary text={'Seller'} mode={'dark'} />
              </div>
              {offers.map((offer, index) => (
                <div key={index} className='header-container-reseller-report'>
                  <HeadingSecondary text={offer.name} mode={'dark'} />
                </div>
              ))}
            </div>
          </div>
          <div className='panel-content-container'>
            <div className='sim-heading-container'>
              <div className='sortby-container'></div>
            </div>
            <div className='scroll-container'>
              {Object.entries(resellerData).map(([reseller, bundles]) => (
                <div key={reseller} className="reseller-block-products-report">
                  <div className="sim-info-products-report">
                    <div className="sim-text-bold-report">{reseller}</div>
                  </div>
                  {offers.map((offer, index) => (
                    <div key={index} className="sim-text-bold-report">
                      {bundles[offer.name] || 0}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Modal isShowing={isModalShowing} hide={toggleModal}>
        <BasicForm onSubmit={handleFormSubmit} />
      </Modal>
    </div>
  );
};

export default ResellerReportScreen;
