import React from 'react';
import { TokenProvider } from '../../api/TokenContext.js';
import { SimProvider } from '../../api/SimContext.js';
import { Routes, Route } from 'react-router-dom';
import MainLayout from '../../components/layouts/layout/main-layout.component';
import Homescreen from '../../screens/homescreen/homescreen.component.jsx';
import Settingsscreen from '../../screens/settingsscreen/settingsscreen.component';
import Vasscreen from '../../screens/vasscreen/vasscreen.component';
import StoreScreenFull from '../../screens/storescreen-full/storescreen.component';
import ManageScreen from '../../screens/managescreen/managescreen.component';
import VoipScreen from '../../screens/voipscreen/voipscreen.component';
import UsageScreen from '../../screens/sim-usagescreen/usagescreen.component';
import Aboutscreen from '../../screens/aboutscreen/about.component';
import Contactscreen from '../../screens/contactscreen/contact.component';
import ResellerOverviewScreen from '../../screens/reselleroverviewscreen/reselleroverviewscreen.component.jsx';
import ResellerSoldSimsScreen from '../../screens/resellersimscreen/resellersimscreen.component';
import ResellerProductScreen from '../../screens/resellerproductscreen/resellerproductscreen.component';
import { DataProvider } from '../../api/DataContext.js';
import AthalosscreenHome from '../../screens/athalosscreen/athalosscreen-home.component.jsx';
import PrsScreen from '../../screens/prsscreen/prsscreen.component.jsx'
import OwnScreen from '../../screens/ownscreen/ownscreen.component.jsx'
import AthalosScreen from '../../screens/athalosscreen/athalosscreen.component.jsx'
import Noconnection from '../../screens/noconnectionscreen/noconnection.component.jsx'
import ResellerScreen from '../../screens/resellerscreen/resellerscreen.component.jsx';
import ResellerReportScreen from '../../screens/resellerreportscreen/resellerreportscreen.component.jsx';

function RoleBasedLayout({ role }) {
  const tokenSimProvider = (
    <TokenProvider>
      <SimProvider>
        <MainLayout />
      </SimProvider>
    </TokenProvider>
  );
  const tokenSimDataProvider = (
    <DataProvider>
        <TokenProvider>
        <SimProvider>
            <MainLayout />
        </SimProvider>
        </TokenProvider>
    </DataProvider>
  );
  switch (role) {
    case 'Reseller':
      return (
        <Routes>
          <Route element={tokenSimProvider}>
            <Route index element={<Homescreen />} />
            <Route path="home" element={<Homescreen />} />
            <Route path="settings" element={<Settingsscreen />} />
            <Route path="value-added-services" element={<Vasscreen />} />
            <Route path="store" element={<StoreScreenFull />} />
            <Route path="reseller" element={<ResellerScreen />} />
          </Route>
        </Routes>
      );
      case 'TT':
        return (
          <Routes>
            <Route element={tokenSimProvider}>
              <Route index element={<Homescreen />} />
              <Route path="home" element={<Homescreen />} />
              <Route path="settings" element={<Settingsscreen />} />
              <Route path="value-added-services" element={<Vasscreen />} />
              <Route path="store" element={<StoreScreenFull />} />
              <Route path="reseller" element={<ResellerScreen />} />
            </Route>
          </Routes>
        );
    case 'Admin':
      return (
        <Routes>
          <Route element={tokenSimDataProvider}>
          <Route index element={<Homescreen />} />
        <Route path="home" element={<Homescreen />} />
        <Route path="settings" element={<Settingsscreen />} />
        <Route path="portal-athalos" element={<AthalosScreen />} />
        <Route path="portal-prs" element={<PrsScreen />} />
        <Route path="portal-own" element={<OwnScreen />} />
        <Route path="value-added-services" element={<Vasscreen />} />
        <Route path="portal-home" element={<AthalosscreenHome />} />
        <Route path="overview" element={<UsageScreen />} />
        <Route path="manage" element={<ManageScreen />} />
        <Route path="store" element={<StoreScreenFull />} />
        <Route path="messages" element={<Contactscreen />} />
        <Route path="voip" element={<VoipScreen />} />
        <Route path="reseller" element={<ResellerScreen />} />
        <Route path="about" element={<Aboutscreen />} />
        <Route path="contact" element={<Contactscreen />} />
        <Route path="no-connection" element={<Noconnection />} />
        </Route>
        </Routes>
      );
    default:
      return (
      <Routes>
        <Route element={tokenSimProvider}>
          <Route index element={<Homescreen />} />
          <Route path="home" element={<Homescreen />} />
          <Route path="settings" element={<Settingsscreen />} />
        </Route>
      </Routes>
    );
  }
}

export default RoleBasedLayout;