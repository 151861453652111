import { useSelector } from 'react-redux';
import PrimaryBtn from '../../components/buttons/primary-btn/primary-btn.component';
import Paragraph from '../typography/paragraph/paragraph.component';
import { IoIosRocket } from 'react-icons/io';

import defaultBackground from '../../assets/img/nasa.jpg';

import './service-block.styles.scss';

const ServiceBlock = ({ props = [], setSrc, setPageName}) => {
  // eslint-disable-next-line
  const [id, name, shortName, DESTINATION_URL, desc, LOGO_URL, background] = props; // destructuring the properties of the service block
  const { activeIndex } = useSelector((state) => state.gridList);

  const blockStyles = {
    container: {
      background: `var(--page-container-color)`, // Combines color and image
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
  };
  let logo_color;
  if (background.substring(0, 3) !== 'col') {
    if (background.substring(0, 3) === 'url') {
      logo_color = '#444444';
    }
  } else {
    logo_color = background.substring(4);
  }

  return (
    <div
      style={blockStyles.container}
      className={`service-block ${activeIndex === 0 ? 'list' : 'grid'}`}
    >
      <Paragraph text={id} cname="service-block-id" />
      <Paragraph text={shortName} cname="service-block-heading" />
      <div className="service-block-line"></div>
      <PrimaryBtn
        text={`Launch ${activeIndex === 0 ? 'service' : ''}`}
        icon={<IoIosRocket color={logo_color} />}
        href={DESTINATION_URL}
        className="service-block-link"
        onClick={() => {
          setSrc(DESTINATION_URL);
          setPageName(shortName);
        }}
        
      />
    </div>
  );
};

export default ServiceBlock;
