import React from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, Area } from "recharts";
import '../../screens/ownscreen/ownscreen.styles.scss';

const CustomTooltip = ({ active, payload, coordinate }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          position: 'absolute',
          left: coordinate.x,
          top: coordinate.y - 30, 
          backgroundColor: 'transparent',
          padding: '2px 5px',
          borderRadius: '3px',
          textAlign: 'center',
          pointerEvents: 'none', 
          transform: 'translate(-50%, -100%)', 
        }}
      >
        <p style={{ margin: 0, color: 'black', fontSize: '14px', fontWeight: 'bold' }}>
          {`${payload[0].value} MB`}
        </p>
      </div>
    );
  }

  return null;
};

const LineGraph = ({ data }) => {
  const formatYAxis = (tick) => {
    return `${tick} MB`;
  };
console.log(data)
  return (
    <ResponsiveContainer className='dynamic-graph-usage' width="100%" height={400}>
      <LineChart
        data={data}
        margin={{
          top: 15,
          right: 30,
          left: 30, 
          bottom: 15,
        }}
      >
        <defs>
          <linearGradient id="colorUsage" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="var(--dynamic-color)" stopOpacity={0.8} />
            <stop offset="95%" stopColor="var(--dynamic-color)" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis 
          dataKey="date" 
          tick={{ fontSize: 10, fill: 'black' }}
          axisLine={{ stroke: '#CCCCCC', strokeWidth: 1 }}
          tickLine={false}
        />
        <YAxis 
          tick={{ fontSize: 10, fill: 'black', fontWeight: 'bold'  }}
          tickFormatter={formatYAxis}
          axisLine={false}
          tickLine={false}
        />
        <Tooltip 
          content={<CustomTooltip />} 
          cursor={{ stroke: 'none', strokeWidth: 0 }} 
        />
        <Area
          type="monotone"
          dataKey="usage"
          stroke="none"
          fill="url(#colorUsage)"
          fillOpacity={1}
        />
        <Line
          type="monotone"
          dataKey="usage"
          stroke="var(--dynamic-color)"
          strokeWidth={4}
          dot={false}
          activeDot={{ r: 8, fill: 'var(--dynamic-color)', stroke: '#ffffff', strokeWidth: 2 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default LineGraph;
