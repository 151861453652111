import React, { createContext, useState, useEffect } from "react"
import axios from 'axios';


export const DataContext = createContext({
  records: [],
  dailyrecords: [],
  callrecords: [],
  
})

export const DataProvider = ({ children }) => {
  const [records, setRecords] = useState([])
  const [dailyrecords, setdailyRecords] = useState([])
  const [callrecords, setcallRecords] = useState([])


 
  useEffect(() => {      
    fetch(
      "https://api.athalos.com/port_api/api_octo_summonth.php?lgn=OcTo_010119cdrmonth"
    )
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        return response.text()
      })
      .then(data => {
        setRecords(data.split("#~#"))
      })
      .catch(error => {
        console.error("Error fetching the data:", error)
      })
  }, [])

  useEffect(() => {
    fetch(
      "https://api.athalos.com/port_api/api_octo_sumtoday.php?lgn=OcTo_010119cdrtoday"
    )
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        return response.text()
      })
      .then(data => {
        setdailyRecords([data])
      })
      .catch(error => {
        console.error("Error fetching the data:", error)
      })
  }, [])


  useEffect(() => {
    fetch(
      "https://api.athalos.com/port_api/api_octo_detailtoday.php?lgn=OcTo_010119cdrtoday"
    )
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        return response.text()
      })
      .then(data => {
        setcallRecords(data.split("#~#"))
   
      })
      .catch(error => {
        console.error("Error fetching the data:", error)
      })
  }, [])

  return (
    <DataContext.Provider value={{ records, dailyrecords, callrecords }}>
      {children}
    </DataContext.Provider>
  )
}
