import React, { useContext } from 'react';
import { SimContext } from '../../../api/SimContext';
import DonutChart from '../../donutchart/donutchart.component';
import HeadingSecondary from '../../typography/headings/heading-secondary/heading-secondary.component';
import '../../../screens/homescreen/homescreen.styles.scss';

const ResellerComponent = () => {
  const { simCardStatus, allSims } = useContext(SimContext);

  const chartData = [
    { name: 'SIMs Sold', value: simCardStatus.offlineActive + simCardStatus.onlineActive },
    { name: 'SIMs Available', value: simCardStatus.offlineInactive },
    { name: 'Total SIMs', value: simCardStatus.offlineInactive + simCardStatus.offlineActive + simCardStatus.onlineActive + simCardStatus.onlineInactive },
  ];

  const prepareChartData = (simData) => {
    let noOfferCount = 0;
    let offerCount = 0;

    simData.forEach((sim) => {
      if (sim.offer === 'NO_OFFER') {
        noOfferCount += 1;
      } else {
        offerCount += 1;
      }
    });
  
    return [
      { name: 'Expired SIMs', value: noOfferCount },
      { name: 'Active SIMs', value: offerCount },
      { name: 'Total SIMs', value: offerCount + noOfferCount },
    ];
  };

  const chartData2 = prepareChartData(allSims);

  return (
    <div className='reseller-home-container'>
      <div className='reseller-block'>
        <div className='reseller-heading-container'>
          <HeadingSecondary text={'SIMs Stock Overview'} mode={'dark'} />
        </div>
        <div className='chart-container'>
          <DonutChart
            data={chartData}
            primaryColor={'var(--dynamic-color)'}
            title={'SIMs Available'}
          />
        </div>
      </div>
      <div className='reseller-block'>
        <div className='reseller-heading-container'>
          <HeadingSecondary text={'Sold SIMs Overview'} mode={'dark'} />
        </div>
        <div className='chart-container'>
          <DonutChart
            data={chartData2}
            primaryColor={'var(--dynamic-color)'}
            title={'SIMs Active'}
          />
        </div>
      </div>
    </div>
  );
};

export default ResellerComponent;
