import React, {useState, useRef} from 'react';
import { Link } from 'react-router-dom';
import { IoIosRedo } from "react-icons/io";
import HeadingPrimary from '../../components/typography/headings/heading-primary/heading-primary.component';
import HeadingSecondary from '../../components/typography/headings/heading-secondary/heading-secondary.component';

import Arrow from '../../assets/img/purple_arrow_down.png'
import './athalosscreen.styles.scss';

import CallsListgas from '../../api/CallsList_gas';
import TotalCallsGas from '../../api/TotalCallsComponent_gas';
import TotalMinutesGas from '../../api/TotalMinutesComponent';
import TotalAcdGas from '../../api/TotalAcd_gas';
import TotalAsrGas from '../../api/TotalAsr_gas';
import MonthlyStatsgas from '../../api/Graph_api_gas';
import MapComponentGas from '../../components/map/Map_component_gas';
import NavbarGas from './navbar/Navbar_gas'



const AthalosscreenHome = () => {
  const [callsData, setCallsData] = React.useState([])
  const filterMenuRef = useRef(null)
  const [filterMenuDisplay, setFilterMenuDisplay] = useState("none")
  const handleDataFetched = callsdata => {setCallsData(callsdata)}
  const [selectedRange, setSelectedRange] = useState("Last Month")
  const [selectedCountryRange, setSelectedCountryRange] = useState("All Countries")
  const [mapChange, setMapChange] = useState("All Operators")
  const appear = () => {
    if (filterMenuRef.current) {
        if (filterMenuDisplay === "none") {
            setFilterMenuDisplay("flex");
        } else {
            setFilterMenuDisplay("none");
        }
    }
  };
  const handleDropdownChange = range => {setSelectedRange(range)}
  const handleMapChange = country => {setMapChange(country)}
  return (
        <div className="portal-container">
          <HeadingSecondary text={'KPI-Portal'} />
          <div className='flex-container'>
            <div className='sub-container'>
              <div className='navbar-container'>
                <div className='navbar'>
                  <div className='active-filters purple-text-medium'>{selectedRange} - {selectedCountryRange} - {mapChange}</div>
                    <div className="element-container">
                      <NavbarGas
                        onDropdownChange={handleDropdownChange}
                        onDropdownCountryChange={setSelectedCountryRange}
                        onMapChange={handleMapChange}
                      />
                    </div> 
                </div>
              </div>
                <div className='gray-box-container'>
                  <div className="gray-box1">
                    <div ref={filterMenuRef} className='filtermenu' style={{ display: filterMenuDisplay }}>
                      <div className='bNumberType'>A-Number</div>
                      <div className='aNumberType'>B-Number (Type)</div>
                      <div className='provider'>Provider</div>
                      <div className='causeText'>Cause Text</div>
                      <div className='providerPriceType'>Provider Price/Type</div>
                      <div className='countryRangetitle'>Country Range</div>
                      <div className='dateTime'>Start DateTime (UTC)</div>
                      <div className='partner'>Partner</div>
                      <div className='cliNetwork'>CLI Network</div>
                      <div className='payoutOur'>Payout Our</div>
                      <div className='status'>Status</div>
                      <div className='duration'>Duration</div>
                      <div className='causeCode'>Cause Code</div>
                      <div className='cliBreakout'>CLI Breakout</div>
                      <div className='payoutPart'>Payout Part.</div>
                    </div>
                      <div className= 'last-calls'>Last Calls </div>
                      <img className='arrow' src={Arrow} alt='purple arrow down' onClick={appear}></img>
                      <div className='last-callscontainer'>
                      <CallsListgas onDataFetched={handleDataFetched} selectedCountryRange={selectedCountryRange}/>
                      {callsData.map((call, index) => ( 
                        <div key={index} className='dynamicRectangle' style={{ top: `${10 + index * 120}px` }}>
                          <div className='purple-text-small ccname'>{call[0]}</div>
                          <div className='purple-text-small anum'>{call[1]}</div>
                          <div className='purple-text-small bnum'>{call[2]}</div>
                          <div className='purple-text-small cdur'>{call[3]} sec</div>
                          <div className='purple-text-small cdati'>{call[4]}</div>  
                          <div className={call[5] === "Answered" ? 'cstat' : call[5] === "Rejected" ? 'cstatre' : 'cstatna'}>{call[5]}</div> 
                          <div className='purple-text-small cprname'>{call[6]}</div>
                          <div className='purple-text-small cpaname'>{call[7]}</div>
                          <div className='purple-text-small cclname'>{call[8]}</div>
                          {/* <img  className='flag' src={"TBD"} alt='country flag'></img>  */}
                          <div className='purple-text-small cclcode'>+{call[9]}</div>
                          <div className='purple-text-small causec'>{call[10].slice(3)}</div>
                          <div className={call[11] === "Numb NotInService prompt (NoAns)" ? 'purple-text-small causetna' : 'purple-text-small causet'}>{call[11]}</div>
                          <div className='purple-text-small cwhod'>{call[12]}</div>
                          <div className='purple-text-small cendup'>{call[13]} EUR</div>
                          <div className='purple-text-small courp'>{call[14]} EUR</div>
                          <div className='purple-text-small partp'>{call[15]} EUR</div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="gray-box2">
                    <div className="traffic-today">Traffic</div>
                    <div className='container-1'>
                      <TotalCallsGas selectedRange={selectedRange}/>
                      <TotalMinutesGas selectedRange={selectedRange}/>
                      <TotalAsrGas selectedRange={selectedRange}/>
                      <TotalAcdGas selectedRange={selectedRange}/>
                      <div className='purple-text-medium total-calls-answered-label'>Calls answered</div>
                      <div className='purple-text-medium minutes-called-label'>Minutes called </div>
                      <div className='purple-text-medium total-calls-label'>Total calls </div>
                      <div className='purple-text-medium total-asr-label'>ASR</div>
                      <div className='purple-text-medium total-acd-label'>ACD</div>
                    </div>
                    <div className='container-2'>
                      <div className='graph-container'>
                        <MonthlyStatsgas className='dynamic-graph' selectedRange={selectedRange}/>
                      </div>
                    </div>
                  </div>
                  <div className="gray-box3">
                    <div className='global-access'>Global Access</div>
                    <MapComponentGas mapChange={mapChange}/>
                  </div>
                </div>
              </div>
            </div>
        </div>
  );
};

export default AthalosscreenHome;