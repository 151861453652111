import React from 'react';
import ReactDOM from 'react-dom';
import { IoClose } from "react-icons/io5";
import './Modal.scss'; // Make sure to create a corresponding CSS file

const Modal = ({ isShowing, hide, children }) => isShowing ? ReactDOM.createPortal(
  <React.Fragment>
    <div className="modal-overlay"  />
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog" onClick={hide}>
      <div className="sim-modal" onClick={(e) => e.stopPropagation()}>
        <IoClose size={27} color='black' onClick={hide}/>
        {children}
      </div>
    </div>
  </React.Fragment>, document.body
) : null;

export default Modal;