import React, { useEffect, useContext } from "react"
import { DataContext } from "./DataContext"

function CallsListgas({ className, onDataFetched, selectedCountryRange }) {
  const { callrecords } = useContext(DataContext)

  useEffect(() => {
    try {
      const formattedCalls = []

      callrecords.forEach(callrecord => {
        const parts = callrecord.split(">>")
        const ccnamePart = parts.find(part => part.startsWith("cclname="))
        const countryData = ccnamePart
          ? ccnamePart.split("=")[1].slice(0, 2)
          : ""

        if (
          selectedCountryRange === "All Countries" ||
          countryData === selectedCountryRange
        ) {
          const callData = []
          parts.forEach(part => {
            const [, value] = part.split("=")
            if (value) {
              callData.push(value)
            }
          })
          formattedCalls.push(callData)
        }
      })

      onDataFetched(formattedCalls)
    } catch (error) {
      console.error("Error processing the data:", error)
    }
  }, [callrecords, selectedCountryRange])

  return <div className={className}></div>
}

export default CallsListgas
