import './expired.styles.scss';
import { useContext, useState, useEffect } from 'react';
import { SimContext } from '../../api/SimContext';
import HeadingSecondary from '../../components/typography/headings/heading-secondary/heading-secondary.component';
import { IoMdSearch } from 'react-icons/io';
import Dropdown from '../../components/dropdown/dropdown.component';
import { IoReloadCircleSharp } from 'react-icons/io5';
const ExpiredScreen = ({setExpirationCount}) => {
    const { allSims, offers, sellSim, reloadKey, setReloadKey, simToInv } = useContext(SimContext);
    const [isSpinning, setIsSpinning] = useState(false);
    const [selections, setSelections] = useState({});
    const handleReload = () => {
      setIsSpinning(true);
      setTimeout(() => {
        setReloadKey(reloadKey + 1);
        setIsSpinning(false);
      }, 1000); // Match this duration with the CSS animation duration
    };
    const [searchTerm, setSearchTerm] = useState('');
    const productOptions = offers.map(offer => ({
      'name': offer.name,
      'id': offer.id
    }));
    // const productOptions = offers.map(offer => ({ label: offer.name, value: offer.id })); // NIEUW
    const durationOptions = [
      { name: "1 day", dayNumber: 1 },
      { name: "3 days", dayNumber: 3 },
      { name: "7 days", dayNumber: 7 }
  ];
    const [filteredExpiredSims, setFilteredExpiredSims] = useState([]);
    // const handleSelect = (simId, option, type) => {
    //   setSelections(prev => ({
    //     ...prev,
    //     [simId]: {
    //       ...prev[simId],
    //       [type]: option,
    //       sold: prev[simId]?.sold || false  
    //     }
    //   }));
    // };
    // const handleSell = (simId) => {
    //   const simSelections = selections[simId];
    //   if (!simSelections || !simSelections.product || !simSelections.duration) {
    //     console.log("No selections made yet"); // Log if selections are incomplete
    //     return; // Prevent execution of the sell function
    //   }
    //   if (!simSelections.sold) {
    //     sellSim(simId, simSelections.product.id);
    //     setSelections(prev => ({
    //       ...prev,
    //       [simId]: {
    //           ...prev[simId],
    //           sold: true  
    //       }
    //     }));
    //     setTimeout(() => {
    //       setFilteredInventorySims(currentSims => currentSims.filter(s => s.simId !== simId));
    //     }, 2000); 
    //   } else {
    //     console.log("SIM is already sold.");
    //   }
    // };
    console.log(allSims)
    useEffect(() => {
        const filtered = allSims.filter(sim => {
          // Include only SIMs that match the exclusion conditions
         if (sim.offer === "NO_OFFER" && sim.tags && sim.tags.some(tag => tag.includes("Sold"))) {
            // Keep the search functionality intact
            return (
              (sim.msisdn && sim.msisdn.toString().toLowerCase().includes(searchTerm.toLowerCase())) || 
              (sim.eid && sim.eid.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
              (sim.offer && sim.offer.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
              (sim.tags && sim.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase())))
            );
          }
          
          return false; 
        });
      
        setFilteredExpiredSims(filtered);
      }, [allSims, searchTerm]);
      useEffect(() => {
        setExpirationCount(filteredExpiredSims.length)
      }, [filteredExpiredSims.length]);
      console.log(filteredExpiredSims, 'expired sims')
    return (
      <div className='content-container-component'>
          <div className="messages-container">
          <div className='table-heading-container'>
              <IoReloadCircleSharp size={28} color='black' onClick={handleReload} style={{ cursor: 'pointer'}} className={`reload-div ${isSpinning ? 'spin-animation' : ''}`} />
              <div className="search-container">
              <IoMdSearch size={28} color= '#646272'/>
                <input 
                    type="text" 
                    className="search-input" 
                    placeholder="Search SIM"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            </div>
              <div className='panel-small'>
                <div className='panel-heading'> 
                  <div className='inner-panel-heading'>
                    <div className='header-container-reseller'>
                      <HeadingSecondary text={`MSISDN - SIM EID`} mode={'dark'}/>
                    </div>
                    <div className='header-container-reseller'>
                      <HeadingSecondary text={`Product`} mode={'dark'}/>
                    </div>
                    <div className='header-container-reseller'>
                      <HeadingSecondary text={`Duration`} mode={'dark'}/>
                    </div>
                    <div className='header-container-reseller'>
                      <HeadingSecondary text={`Action`} mode={'dark'}/>
                    </div>
                  </div>
                </div>
                    <div className='panel-content-container'>
                        <div className='sim-heading-container'>
                            <div className='sortby-container'>
                            </div>
                        </div>
                        <div className='scroll-container'>
                            {filteredExpiredSims.map(sim => (
                                <div key={sim.eid} className="reseller-block-table">
                                    <div className="sim-info-reseller">
                                      <div className="sim-text-bold">{sim.msisdn}<br/>{sim.eid}

                                        </div>
                                    </div>
                                    <div className='dropdown-container'>
                                    <div className="duration-block">
                                        <div className='sim-text'>{sim.offer}</div>
                                    </div>
                                    </div>
                                    <div className='dropdown-container'>
                                    <div className="duration-block">
                                        <div className='sim-text'>{sim.activatedDate}</div>
                                    </div>
                                    </div>
                                    <div className='sell-button-container' onClick={() => {
                                            if (!selections[sim.simId]?.sold) {
                                                simToInv(sim.simId);
                                                setSelections(prev => ({
                                                  ...prev,
                                                  [sim.simId]: {
                                                      ...prev[sim.simId],
                                                      inv: true  
                                                  }
                                              }));
                                              setTimeout(() => {
                                                setFilteredExpiredSims(currentSims => currentSims.filter(s => s.simId !== sim.simId));
                                            }, 2000); 
                                            } else {
                                                console.log("SIM is already out of inventory."); 
                                            }
                                        }}>
                                        <div className={`${selections[sim.simId]?.inv ? 'loader' : 'sell-block'}`}> 
                                            <div className='sim-text'>{selections[sim.simId]?.inv ? '' : 'Set Inventory'}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        
                    </div>
              </div>
          </div>
      </div>
  );
};

export default ExpiredScreen;