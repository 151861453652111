import React, { useState, useEffect, useContext } from "react"
import { DataContext } from "./DataContext"

function TotalAsrGas({ className, selectedRange }) {
  const [asr, setAsr] = useState(null)
  const { records, dailyrecords } = useContext(DataContext)

  // Process data whenever selectedRange changes
  useEffect(() => {
    if (records && records.length > 0) {
      let filteredrecords
      if (selectedRange === "Today") {
        filteredrecords = dailyrecords
      } else if (selectedRange === "Last 7 days") {
        filteredrecords = records.slice(0, 7)
      } else if (selectedRange === "Last Month") {
        filteredrecords = records
      } else {
        filteredrecords = dailyrecords
      }

      let totalCalls = 0
      let totalAnsweredCalls = 0

      filteredrecords.forEach(record => {
        const parts = record.split(">>")
        parts.forEach(part => {
          const [key, value] = part.split("=")
          if (key === "calls") {
            totalCalls += parseInt(value, 10)
          } else if (key === "callsa") {
            totalAnsweredCalls += parseInt(value, 10)
          }
        })
      })

      const calculatedAsr =
        totalCalls > 0 ? (totalAnsweredCalls / totalCalls) * 100 : 0
      setAsr(calculatedAsr)
    }
  }, [selectedRange, records])

  return (
    <div className={className}>
      <div className="purple-text-big total-asr">
        {asr !== null ? asr.toFixed(1) + "%" : "N.A."}
      </div>
    </div>
  )
}

export default TotalAsrGas
