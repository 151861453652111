import React, { useState, useEffect, useContext } from "react";
import { SimContext } from "./SimContext";
import LineGraph from "../components/graphs/Graph_mt_usage";

// Add a prop to specify the data type (individual or pooled)
function UsageApi({ dataType }) {
  const [dataPoints, setDataPoints] = useState([]);
  const { usageArray } = useContext(SimContext);

  useEffect(() => {
    // Determine which data source to use based on the dataType prop
    const sourceData = usageArray;

    const newPoints = sourceData
      .filter(record => record.inputQuantity !== undefined && record.inputQuantity !== null)
      .map(record => ({
        date: record.date,
        usage: Math.round((record.inputQuantity / 1024))
      }));

    setDataPoints(newPoints);
  }, [usageArray]); // Include all dependencies

  return (
    <div style={{ width: '100%', height: 400 }}>
      {dataPoints.length > 0 ? (
        <LineGraph data={dataPoints} />
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <h3>No Data Available</h3>
        </div>
      )}
    </div>
  );
}

export default UsageApi;