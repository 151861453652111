import React, { useContext, useState, useEffect } from 'react';
import HeadingPrimary from '../../components/typography/headings/heading-primary/heading-primary.component';
import HeadingSecondary from '../../components/typography/headings/heading-secondary/heading-secondary.component';
import UsageApi from '../../api/Graph_api_usage';
import Sidebar from '../../components/sidebar/sidebar.component';
import './usagescreen.styles.scss';
import { connect, useSelector } from 'react-redux';
import HeaderComponent from '../../components/header-component/header.component';
import { SimContext } from '../../api/SimContext';
import UsagePieChart from '../../components/piechart/piechart.component';
import UsageBarChart from '../../components/barchart/barchart.component'
import ConnectionChart from '../../components/graphs/Connection_chart';
import MapComponentSim from '../../components/map/map_component_sim';
import Modal from '../../components/modal/Modal';
import Selector from '../../components/selector/Selector.component';
import Dropdown from '../../components/dropdown/dropdown.component';
// REACT ICONS
import { IoClose } from "react-icons/io5";
import { PiWarningCircleFill } from "react-icons/pi";
import { IoMdUnlock } from "react-icons/io";
import { IoMdArrowRoundUp } from "react-icons/io";
import { IoMdArrowRoundDown } from "react-icons/io";
import { VscBracketError } from "react-icons/vsc";
import { MdKeyboard, MdKeyboardArrowLeft, MdMessage } from "react-icons/md";
import { BiSolidBarChartAlt2 } from 'react-icons/bi';
import { BsFillCircleFill } from "react-icons/bs";
import { MdOfflineBolt } from "react-icons/md";
import { MdNetworkWifi } from "react-icons/md";
import { TbRuler } from 'react-icons/tb';
import { IoMdSearch } from "react-icons/io";
import { GoArrowSwitch } from "react-icons/go";
import { MdOutlineMoreHoriz } from "react-icons/md";
import { IoMdCalendar } from "react-icons/io";
import { MdKeyboardArrowDown, MdSimCard } from 'react-icons/md';
import { MdGroups } from "react-icons/md";


const UsageScreen = () => {
  const [simsCollapsed, setSimsCollapsed] = useState(false)
  const [poolsCollapsed, setPoolsCollapsed] = useState(false)
  const [select, setSelect] = useState('Pools')
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSims, setFilteredSims] = useState([])
  const isMobile = window.innerWidth <= 766;
  const [showSidebarMobile, setShowSidebarMobile] = useState(false);
  const options = [
    { label: 'Today', value: 0 },
    { label: 'Last week', value: 7 },
    { label: 'Last Month', value: 30 }
  ];
 const toggleSelect = () => {
    setSelect(select === 'Pools' ? 'Sims' : 'Pools');
  };

  const simContainerStyle = {
    height: simsCollapsed ? '5rem' : ' ',
    overflowY: simsCollapsed ? 'hidden' : 'scroll',
  };
  const poolContainerStyle = {
    height: poolsCollapsed ? '5rem' : ' ',
    overflowY: poolsCollapsed ? 'hidden' : 'scroll',
  };
  const arrowStyleSims = {
    transition: 'transform 0.3s', 
    transform: simsCollapsed ? 'rotate(90deg)' : 'rotate(0deg)',
    marginLeft: '1rem'
  };
  const arrowStylePools = {
    transition: 'transform 0.3s', 
    transform: poolsCollapsed ? 'rotate(90deg)' : 'rotate(0deg)'
  }
  const {
    simIdIndividual,
    setSimIdIndividual,
    startDateIndividual,
    setStartDateIndividual,
    simCardStatus,
    eid,
    status,
    connection,
    poolsData,
    simId,  
    setDataType,
    locationData,
    setPoolId,
    poolId,
    poolUsage,
    connectionHistory,
    networkEvents,
    startDateNetwork,
    setStartDateNetwork
  } = useContext(SimContext);

  // Function to handle changes in the string input
  let totalSimcards = simCardStatus.offlineInactive + simCardStatus.offlineActive + simCardStatus.onlineActive;
  const handleSimIdChange = (event) => {
    setSimIdIndividual(event.target.value);
  };

  // Function to handle changes in the date input
  const handleStartDateChange = (event) => {
    setStartDateIndividual(event.target.value);
  };

    // Function to handle changes in the date input
    const handleStartDateNetwork = (event) => {
      setStartDateNetwork(event.target.value);
    };
  
  const iconMapping = {
    DATA_SESSION_NOTIFICATION: <PiWarningCircleFill size={22} color='var(--heading-color)'/>, 
    DATA_SESSION_ONLINE: <IoMdArrowRoundUp size={22} color='var(--heading-color)'/>,
    DATA_SESSION_AUTHORIZATION: <IoMdUnlock size={22} color='var(--heading-color)'/>,
    DATA_SESSION_OFFLINE: <IoMdArrowRoundDown size={22} color='var(--heading-color)'/>,
    SMS_MO: <MdMessage size={22} color='var(--heading-color)'/>
  };
  
  const showSidebar = useSelector((state) => state.ui.sidebarOpen)
const [isModalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const handleSelectionChangeUsage = (selectedValue) => {
    const today = new Date();
    const pastDate = new Date(today);
    pastDate.setDate(today.getDate() - selectedValue);
    const formattedDate = pastDate.toISOString().split('T')[0];
    setStartDateIndividual(formattedDate);
  };
  const handleSelectionChangeNetwork = (selectedValue) => {
    const today = new Date();
    const pastDate = new Date(today);
    pastDate.setDate(today.getDate() - selectedValue);
    const formattedDate = pastDate.toISOString().split('T')[0];
    setStartDateNetwork(formattedDate);
  };

  useEffect(() => {
    const filtered = eid.filter(sim => {
        return (
          (sim.msisdn && sim.msisdn.toString().toLowerCase().includes(searchTerm.toLowerCase())) || 
          (sim.eid && sim.eid.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
          (sim.offer && sim.offer.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
          (sim.tags && sim.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase())))
        );
    });
  
    setFilteredSims(filtered);
  }, [eid, searchTerm]);

  return (
    <div className='header-layout'>
    <div className="bottom-nav-container">
      <HeaderComponent showSidebarMobile={showSidebarMobile}
      setShowSidebarMobile={setShowSidebarMobile} />
    </div>
    <div className='portalscreen-layout'>
    {isMobile ? (
      <div className={`sidebar-container-mobile ${showSidebarMobile ? 'sidebar-visible' : ''}`}>
        <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
          <Sidebar />
        </div>
      </div>
    ) : (
      <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
        <Sidebar />
      </div>
    )}
       <div className={'content-container'}>
       <Modal isShowing={isModalVisible} hide={hideModal}> 
          <div className='sim-heading-container'>
            <div className='sortby-container'>
              <HeadingSecondary text={`Select ${select}`} mode={'dark'}/>
              <GoArrowSwitch size={28} color='black' onClick={toggleSelect} style={arrowStyleSims} />
            </div>
            <div class="search-container">
            <IoMdSearch size={28} color= '#646272'/>
              <input type="text" class="search-input" placeholder={`Search ${select}`} value={searchTerm} />
            </div>
          </div>
          <div className='sim-list-content-container'>
            <div className="sim-block">
                <div className="sim-info">
                  <div className="sim-text-bold">All Sims/Pools</div>
                </div>
                <div className="sim-info">
                  <div className="sim-text-bold">44/100MB</div>
                </div>
                <div className="usage-block"  onClick={() => console.log('click')}>
                  <div className='sim-text-bold-white' >Usage</div>
                </div>
              </div>
            <div className='scroll-container-sims'>
            {select === 'Sims' ? (
          eid.map((eid, index) => (
              <div key={eid} className="sim-block">
                <div className="sim-info">
                  <div className="sim-text-bold">{eid}</div>
                </div>
                <div className="sim-info">
                  <div className="sim-text-bold">44/100MB</div>
                </div>
                <div className="usage-block"   onClick={() => {
                  setSimIdIndividual(simId[index]);
                  setDataType('individual');
                  hideModal(); // Call the hidemodal function
                }}
              >
                  <div className='sim-text-bold-white'>Usage</div>
                </div>
              </div>
          
          ))   ): (
          poolsData.map((pool, poolIndex) => (
            <div key={poolIndex} className="pool-block"> 
              <div className="sim-info">
                <div className="sim-text-bold">{pool.name}</div>
              </div>
              <div className="sim-info">
                <div className="sim-text-bold">44/100MB</div>
              </div>
              <div className="usage-block" onClick={() => { const simId = pool.simCards[0]?.id || 'Pool without sims!'; setSimIdIndividual(simId); setDataType('pooled');  setPoolId(pool.poolId); hideModal()}}>
                <div className='sim-text-bold-white'>Usage</div>
              </div>
            </div>
          )) )}
          </div>
          </div>
       </Modal>
        <div className='sim-content-container'>
        <div className='heading-container'>
              <HeadingPrimary text={'Sim Overview'} mode={'dark'}/>
            </div>
          <div className='vertical-container'>
            <div className='sim-overview-layout'>
            {/* <div className='layout-row'>
              <div className='panel-very-small'>
                <div className='sim-icon-container'>
                  <BiSolidBarChartAlt2 size={37} color='var(--dynamic-color)'/>
                </div>
                <div className="sim-usage-info">
                  <div className="sim-usage-numbers">534/1000</div>
                  <div className="sim-usage-label">Total Usage (MB)</div>
                </div>
              </div>
              <div className='panel-very-small'>
                <div className='sim-icon-container'>
                    <BsFillCircleFill size={37} color='#4fff95'/>
                  </div>
                  <div className="sim-usage-info">
                    <div className="sim-usage-numbers">100/100</div>
                    <div className="sim-usage-label">SIMs Online</div>
                  </div>
                </div>
              <div className='panel-very-small'>
              <div className='sim-icon-container'>
                <MdOfflineBolt size={37} color='var(--dynamic-color)'/>
              </div>
                <div className="sim-usage-info">
                  <div className="sim-usage-numbers">Black Label</div>
                  <div className="sim-usage-label">Network Speed</div>
                </div>
                <div className="edit-container">Edit</div>
              </div>
              <div className='panel-very-small'>
                <div className='sim-icon-container'>
                  <MdNetworkWifi size={37} color='var(--dynamic-color)'/>
                </div>
                  <div className="sim-usage-info">
                    <div className="sim-usage-numbers">Add Data</div>
                    <div className="sim-usage-label">Refill your data</div>
                  </div>
                </div>
            </div> */}
            <div className='layout-row'>
              <div className='panel-big'>
                <div className='sim-heading-container'>
                  <HeadingSecondary text={`Usage Overtime`} mode={'dark'}/>
                  <div className='date-container'>
                  <Selector
                      options={options}
                      accentColor={'var(--dynamic-color)'}
                      onSelectionChange={handleSelectionChangeUsage}
                    />
                  <div className='icon-container'>
                    <input
                        type="date"
                        id="startDateIndividual"
                        value={startDateIndividual}
                        onChange={handleStartDateChange}
                      />
                  </div>
                  </div>
                  <button className="view-all-sims-button" onClick={showModal}>
                   View all SIMS  <MdKeyboardArrowDown size={27} color='black' />
                  </button>
                </div>
                <div className='usage-graph-container'>
                    <UsageApi dataType='pooled' />
                  </div>
              </div>
              </div>
              {/* <div className='layout-row'>
                <div className='panel-small'>
                  <div className='sim-heading-container'>
                    <div className='sortby-container'>
                      <HeadingSecondary text={`Select ${select}`}/>
                      <GoArrowSwitch  size={28} color='var(--heading-color)' onClick={toggleSelect} style={arrowStyleSims}/>
                    </div>
                    <div class="search-container">
                    <IoMdSearch size={28} color= '#646272'/>
                      <input type="text" class="search-input" placeholder={`Search ${select}`} />
                    </div>
                  </div>
                  <div className='panel-content-container'>
                    <div className="sim-block">
                        <div className="sim-info">
                          <div className="sim-text-bold">All Sims/Pools</div>
                        </div>
                        <div className="sim-info">
                          <div className="sim-text-bold">44/100MB</div>
                        </div>
                        <div className="usage-block"  onClick={() => console.log('click')}>
                          <div className='sim-text-bold-white'>Usage</div>
                        </div>
                      </div>
                    <div className='scroll-container-usage'>
                    {select === 'Sims' ? (
                  eid.map((eid, index) => (
                      <div key={eid} className="sim-block">
                        <div className="sim-info">
                          <div className="sim-text-bold">{eid}</div>
                        </div>
                        <div className="sim-info">
                          <div className="sim-text-bold">44/100MB</div>
                        </div>
                        <div className="usage-block"  onClick={() => { setSimIdIndividual(simId[index]); setDataType('individual');}}>
                          <div className='sim-text-bold-white'>Usage</div>
                        </div>
                      </div>
                   
                  ))   ): (
                  poolsData.map((pool, poolIndex) => (
                    <div key={poolIndex} className="pool-block"> 
                      <div className="sim-info">
                        <div className="sim-text-bold">{pool.name}</div>
                      </div>
                      <div className="sim-info">
                        <div className="sim-text-bold">44/100MB</div>
                      </div>
                      <div className="usage-block" onClick={() => { const simId = pool.simCards[0]?.id || 'Pool without sims!'; setSimIdIndividual(simId); setDataType('pooled');  setPoolId(pool.poolId)}}>
                        <div className='sim-text-bold-white'>Usage</div>
                      </div>
                    </div>
                  )) )}
                  </div>
                  </div>
                </div>
                <div className='panel-small'>
                  <div className='sim-heading-container'>
                    <HeadingSecondary text={`Used Data`}/>
                    <div className='sortby-container'>
                      <MdOutlineMoreHoriz size={22} style={{ color: 'var(--dynamic-color)', backgroundColor: 'var(--content-container-color)', borderRadius: '6px', marginLeft:'auto' }}/>
                    </div>
                  </div>
                  <UsagePieChart/>
                </div>
              </div> */}
              
              {/* <div className='layout-row'>
                <div className='panel-small'>
                  <div className='sim-heading-container'>
                    <HeadingSecondary text={`Connection History`}/>
                    <div className='date-container'>
                    <HeadingSecondary text={`1Hr`}/>
                    <HeadingSecondary text={`4Hr`}/>
                    <HeadingSecondary text={`12Hr`}/>
                    <HeadingSecondary text={`24Hr`}/>
                    <div className='icon-container'>
                      <IoMdCalendar size={28} color='var(--heading-color)'/>
                    </div>
                    </div>
                  </div>
                  <div className='connection-graph-container'>
                      <ConnectionChart data={connectionHistory}/>
                    </div>
                </div>
                <div className='panel-small'>
                <div className='sim-heading-container'>
                  <HeadingSecondary text={`Map with active SIM Cards`}/>
                </div>
                <iframe scrolling="no"  title="google" src="https://www.google.com/maps?&amp;output=embed&amp;z=3" width="450" height="450" frameborder="0" allowfullscreen="" data-ll-status="loaded" class="entered loaded"></iframe>    

                </div>
              </div> */}
              <div className='layout-row'>
                <div className='panel-big'>
                  <div className='sim-heading-container'>
                    <HeadingSecondary text={`Network Events`} mode={'dark'}/>
                    <div className='date-container'>
                    <Selector
                      options={options}
                      accentColor={'var(--dynamic-color)'}
                      onSelectionChange={handleSelectionChangeNetwork}
                    />
                      <div className='icon-container'>
                      <input
                          type="date"
                          id="startDateNetwork"
                          value={startDateNetwork}
                          onChange={handleStartDateNetwork}
                        />
                    </div>
                    </div>
                  </div>
                  {networkEvents.length > 0 ? (
                    networkEvents.map((item, index) => (
                      <div key={index} className="network-event-block"> 
                        <span className="sim-icon-container">{iconMapping[item.fieldValue] || <VscBracketError size={22} color='var(--heading-color)'/>}</span> 
                        <span className="sim-text-bold">{item.fieldValue.replace(/_/g, ' ').replace('DATA SESSION', 'Data_session_').replace('SMS MO', 'Sms_mo_')}</span>
                        <span className="sim-text-network-event">{item.count} times</span> 
                      </div>
                    ))
                  ) : (
                    <div className="network-event-block"> 
                      <IoClose size={27} color='var(--heading-color)' className='sim-icon-container'/>
                      <div className="sim-text-bold">No network events available</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
    
    </div>
  );
};

export default UsageScreen;
