import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ServiceBlock from '../../components/service-block/service-block.component';

import HeadingPrimary from '../../components/typography/headings/heading-primary/heading-primary.component';
import HeadingSecondary from '../../components/typography/headings/heading-secondary/heading-secondary.component';

import { IoIosSearch } from 'react-icons/io';
import PortalscreenHome from '../athalosscreen/athalosscreen-home.component';
import { filterItems } from './filterData';
import Dynamicscreen from '../dynamicscreen/dynamicscreen.component';
import './vasscreen.styles.scss';
import { setIndex } from '../../redux/gridListFilter';
import Sidebar from '../../components/sidebar/sidebar.component';

import HeaderComponent from '../../components/header-component/header.component';
const Vasscreen = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user); // get the user array
  const { defaultServices } = useSelector((state) => state.defaultServices);
  const { activeIndex } = useSelector((state) => state.gridList); // state of the grid
  const [src, setSrc] = useState('https://www.oneworldnumber.com/category/store-products');
  const [pageName, setPageName] = useState('Click on a button to get started!');
  const [search, setSearch] = useState(''); // Search functionality
  const [userServices, setUserServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]); 
  const showSidebar = useSelector((state) => state.ui.sidebarOpen)
  const isMobile = window.innerWidth <= 766;
  const [showSidebarMobile, setShowSidebarMobile] = useState(false);

  useEffect(() => {
    function gettingUserServices() {
      const userServicesIds = user[8]?.split('&'); // service ID's are retrieved
      const userServicesArr = userServicesIds.map((el) => {
        return defaultServices.find((arr) => arr.includes(el.substring(0, 3))); // ID's and default services are linked
      });

      setUserServices(userServicesArr); // services are stored in state
    }
   
    if (defaultServices.length) {
      gettingUserServices();
    }
  }, [user, defaultServices]);

  useEffect(() => {
    if (userServices.length) {
      const filtered = userServices.filter(
        (el) => el && el[2].toLowerCase().includes(search.toLowerCase())
      );
      setFilteredServices(filtered);
    }
  }, [search, userServices]);
  return (
    <div className='header-layout'>
    <div className="bottom-nav-container">
      <HeaderComponent showSidebarMobile={showSidebarMobile}
      setShowSidebarMobile={setShowSidebarMobile} />
    </div>
    <div className='portalscreen-layout'>
    {isMobile ? (
      <div className={`sidebar-container-mobile ${showSidebarMobile ? 'sidebar-visible' : ''}`}>
        <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
          <Sidebar />
        </div>
      </div>
    ) : (
      <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
        <Sidebar />
      </div>
    )}
         <div className={'content-container'}>
          <HeadingPrimary text={'Value Added Services'} mode={'dark'} />
          <Dynamicscreen src={src} pageName={pageName} />
          <div className="services-container">
            <div className="services-layout">
              <HeadingSecondary text={`Services`} servicesCount={userServices.length} />
              <div className="services-layout-search">
                <button type="submit" className="services-search-btn">
                  <IoIosSearch size={22} />
                </button>
                <input
                  type="text"
                  className="services-search-input"
                  placeholder="Search for service"
                  onChange={(event) => {
                    setSearch(event.target.value);
                  }}
                ></input>
              </div>

              <div className="services-layout-view">
                {filterItems.map((item, index) => (
                  <div
                    key={index}
                    index={index}
                    className={activeIndex === index ? 'active' : 'inactive'}
                    onClick={() => dispatch(setIndex(index))}
                  >
                    {item.icon}
                  </div>
                ))}
              </div>
            </div>
            <div className="services-search">
              <button type="submit" className="services-search-btn">
                <IoIosSearch />
              </button>
              <input
                  type="text"
                  className="services-search-input"
                  placeholder="Search for service"
                  onChange={(event) => {
                    setSearch(event.target.value);
                  }}
                ></input>
            </div>
            <div className={`services-list ${activeIndex === 1 ? 'grid' : ''}`}>
              {defaultServices.constructor === Array ? (
                filteredServices.map((e, i) => {
                  return <ServiceBlock key={i} props={e} setSrc={setSrc} setPageName={setPageName} />
                })
              ) : (
                <p style={{ color: 'white' }}>{defaultServices}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      </div>
  );
};

export default Vasscreen;
