import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

// const clientId = 'v2_simfony-web';
// const clientSecret = 'd467da0c-d8a7-4069-9481-962e51f7a28d';
const clientId = 'v2_athalos-api';
const clientSecret = 'S7uhEGa9NS';
const authUrl = 'https://login.oneworldnumber.com/auth/realms/qa-simfony/protocol/openid-connect/token';
const authorizationHeaderValue = btoa(`${clientId}:${clientSecret}`);

const TokenContext = createContext();

export const useToken = () => useContext(TokenContext);

export const TokenProvider = ({ children }) => {
  const [token, setToken] = useState(() => sessionStorage.getItem('token'));

  // Function to refresh the access token using the stored refresh token
  const refreshAccessToken = async () => {
    const refreshToken = sessionStorage.getItem('refresh_token');
    if (!refreshToken) {
      console.error('No refresh token available.');
      return;
    }

    const body = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken
    };

    try {
      const response = await axios.post(authUrl, body, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Basic ${authorizationHeaderValue}`,
        }
      });

      const { access_token, refresh_token, refresh_expires_in} = response.data;
      

      setToken(access_token);
      sessionStorage.setItem('token', access_token);
      sessionStorage.setItem('refresh_token', refresh_token);
      sessionStorage.setItem('refresh_token_expiry', refresh_expires_in);
      const refreshTime = sessionStorage.getItem('refresh_token_expiry') * 1000 - 6000
      setTimeout(refreshAccessToken, refreshTime);
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
  };

  useEffect(() => {
    if (!token) {
      refreshAccessToken(); 
    }
  }, []);

  return (
    <TokenContext.Provider value={{ token, refreshAccessToken }}>
      {children}
    </TokenContext.Provider>
  );
};
