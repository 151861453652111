import React, { useState } from 'react';
import './dropdown.styles.scss';
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

const Dropdown = ({ label, options, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Select Option');

  const handleToggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onSelect(option); // Pass the option to onSelect
    setIsOpen(false);
  };

  const displayLabel = option => {
    // Check if the option is an object and has a 'name' property; otherwise, display the option itself
    return typeof option === 'object' && option.name ? option.name : option;
  };

  return (
    <div className="dropdown">
      <button onClick={handleToggleDropdown} className="dropdown-button">
        {displayLabel(selectedOption)}  
        <MdOutlineKeyboardArrowDown className="dropdown-icon" size={28}/>
      </button>
      {isOpen && (
        <ul className="dropdown-list">
          {options.map((option, index) => (
            <li
              key={index}
              onClick={() => handleOptionClick(option)}
              className="dropdown-item"
            >
              {displayLabel(option)}  
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
