import { createSlice } from '@reduxjs/toolkit';

export const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    color: '#846dff', // Default color; adjust as needed
  },
  reducers: {
    setUserColor: (state, action) => {
      state.color = action.payload;
    },
  },
});

// Actions
export const { setUserColor } = themeSlice.actions;

// Selectors
export const selectUserColor = (state) => state.theme.color;

// Reducer
export default themeSlice.reducer;