import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    sidebarOpen: true,
    kpiSubmenuCollapsed: true, 
    simSubmenuCollapsed: true,
    resellerSubmenuCollapsed: true,
  },
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarOpen = !state.sidebarOpen;
    },
    toggleKpiSubmenu: (state) => {
      state.kpiSubmenuCollapsed = !state.kpiSubmenuCollapsed;
    },
    toggleSimSubmenu: (state) => {
      state.simSubmenuCollapsed = !state.simSubmenuCollapsed;
    },
    toggleResellerSubMenu: (state) => {
      state.resellerSubmenuCollapsed = !state.resellerSubmenuCollapsed;
    },
  },
});

export const { toggleSidebar, toggleKpiSubmenu, toggleSimSubmenu, toggleResellerSubMenu } = uiSlice.actions;

export default uiSlice.reducer;
