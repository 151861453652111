import './backgroud-gradient.styles.scss';

const BackgroundGradient = ({ className, gradientImg, blur = '21px' }) => (
  <div
    className={`${className} background-gradient`}
    style={{ backgroundImage: `url(${gradientImg})`, filter: `blur(${blur})` }}
  ></div>
);

export default BackgroundGradient;
