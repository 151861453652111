import React from 'react';
import HeadingSecondary from '../../components/typography/headings/heading-secondary/heading-secondary.component';

import './dynamicscreen.styles.scss';

const Dynamicscreen = ({src, pageName}) => {

  return (
      <div className='dynamic-container'>
        <HeadingSecondary text={pageName} />
        <div className="sub-container">
        <iframe
          title="Embedded Website"
          src={src}
          width="100%"
          height="750px"
          className='store-iframe'
        />
        </div>
      </div>
  );
};

export default Dynamicscreen;
