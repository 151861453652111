// React/Redux imports 
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setUserColor } from '../../redux/themeSlice'

// Components
import Sidebar from '../../components/sidebar/sidebar.component';
import HeaderComponent from '../../components/header-component/header.component';
import ExploreComponent from '../../components/homescreen-components/Explore/explore.component';
import ResellerComponent from '../../components/homescreen-components/Reseller/reseller.component';
import SimOverviewComponent from '../../components/homescreen-components/Sim overview/simoverview.component';
import KpiComponent from '../../components/homescreen-components/KPI/kpi.component';
import VasComponent from '../../components/homescreen-components/Value Added Services/vas.component'

// Typography
import HeadingPrimary from '../../components/typography/headings/heading-primary/heading-primary.component';

import Modal from '../../components/modal/Modal';
import SimForm from '../../components/simform/simform.component';
import './homescreen.styles.scss';

const Homescreen = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user); // get the user array
  const showSidebar = useSelector((state) => state.ui.sidebarOpen)
  const [isModalShowing, setIsModalShowing] = useState(false);
  const toggleModal = () => {
    setIsModalShowing(!isModalShowing);
  };
console.log(isModalShowing)
  const [showSidebarMobile, setShowSidebarMobile] = useState(false);
  const roleBasedComponents = { // Roles to be added
    Reseller: [
      <ExploreComponent key="explore" toggleModal={toggleModal} />,
      <ResellerComponent key="reseller"  />,
      <VasComponent key="vas" />,
    ],
    Admin: [
      <ExploreComponent key="explore" toggleModal={toggleModal}/>,
      <ResellerComponent key="reseller" />,
      <SimOverviewComponent key="simOverview" />,
      <KpiComponent key="kpi" />,
      <VasComponent key="vas" />,
    ],
    TT: [
      <ExploreComponent key="explore" toggleModal={toggleModal}/>,
      <ResellerComponent key="reseller" />,
      <VasComponent key="vas" />,
    ],
    newUser: [
      <ExploreComponent key="explore"toggleModal={toggleModal} />,
    ]
  };

  const userRole = user[7]; 
  console.log(user)
  const componentsToRender = roleBasedComponents[userRole] || [];
  const currentDate = new Date().toLocaleDateString('en-US', {
    weekday: 'long', // Full weekday name (e.g., "Monday")
    day: 'numeric', // Day of the month (e.g., "31")
    month: 'short', // Abbreviated month name (e.g., "Aug")
  });
  const isMobile = window.innerWidth <= 766;
  useEffect(() => {
    // Dispatch setUserColor here within useEffect to avoid the warning
    // Make sure user and user[6] exist before dispatching to avoid undefined errors
    if (user && user[6]) {
      dispatch(setUserColor(user[6]));
    }
  }, [user, dispatch]); // Add dispatch to the dependency array as well
  const getCurrentGreeting = () => {
    const hour = new Date().getHours(); // Get the current hour
    if (hour >= 5 && hour < 12) {
      return 'Good morning';
    } else if (hour >= 12 && hour < 17) {
      return 'Good afternoon';
    } else if (hour >= 17 && hour < 22) {
      return 'Good evening';
    } else {
      return 'Good night';
    }
  };

  return (
    <div className='header-layout'>
       { showSidebarMobile? (
          <div id="overlay" onClick={() => setShowSidebarMobile(!showSidebarMobile)}></div>
      ) : (null)}
      <div className="bottom-nav-container">
        <HeaderComponent showSidebarMobile={showSidebarMobile}
        setShowSidebarMobile={setShowSidebarMobile} />
      </div>
      <div className='homescreen-layout'>
      {isMobile ? (
        <div className={`sidebar-container-mobile ${showSidebarMobile ? 'sidebar-visible' : ''}`}>
          <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
            <Sidebar />
          </div>
        </div>
      ) : (
        <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
          <Sidebar />
        </div>
      )}
         <div className={'content-container'} >
          <div className='heading-container'>
            <HeadingPrimary text={`${getCurrentGreeting()}, ${user[3]}👋`}  secondLineText={currentDate} mode={'dark'}/>
            {/* <div className='notif-container'>
                  <MdCircleNotifications size={35} color='var(--heading-color)'/>
                </div> */}
          </div>
          {componentsToRender}
        </div>
        </div>
        <Modal isShowing={isModalShowing} hide={toggleModal}>
          <SimForm  />
        </Modal>
      </div>
  );
};

export default Homescreen;
