import { createSlice } from '@reduxjs/toolkit';

export const staticData = createSlice({
  name: 'staticData',
  initialState: {
    about: '',
    contact: '',
  },
  reducers: {
    setAboutData: (state, action) => {
      state.about = action.payload;
    },
    setContactData: (state, action) => {
      state.contact = action.payload;
    },
  },
});

export const { setAboutData, setContactData } = staticData.actions;

export default staticData.reducer;
