import './reselleroverviewscreen.styles.scss';
import { useSelector } from 'react-redux';
import Sidebar from '../../components/sidebar/sidebar.component';
import HeaderComponent from '../../components/header-component/header.component';
import HeadingPrimary from '../../components/typography/headings/heading-primary/heading-primary.component';
import { useContext, useState, useEffect } from 'react';
import { SimContext } from '../../api/SimContext';
import HeadingSecondary from '../../components/typography/headings/heading-secondary/heading-secondary.component';
import { IoMdSearch } from 'react-icons/io';
import Dropdown from '../../components/dropdown/dropdown.component';
import { IoReloadCircleSharp } from "react-icons/io5";
const ResellerOverviewScreen = ({setInventoryCount}) => {
  const { allSims, offers, sellSim, reloadKey, setReloadKey } = useContext(SimContext);
  const [isSpinning, setIsSpinning] = useState(false);
  const handleReload = () => {
    setIsSpinning(true);
    setTimeout(() => {
      setReloadKey(reloadKey + 1);
      setIsSpinning(false);
    }, 1000); // Match this duration with the CSS animation duration
  };

    const [searchTerm, setSearchTerm] = useState('');
    const { user } = useSelector((state) => state.user); 
    const productOptions = offers.map(offer => ({
      'name': offer.name,
      'id': offer.id
    }));
    // const productOptions = offers.map(offer => ({ label: offer.name, value: offer.id })); // NIEUW
    const durationOptions = [
      { name: "1 day", dayNumber: 1 },
      { name: "3 days", dayNumber: 3 },
      { name: "7 days", dayNumber: 7 }
  ];
    const [selections, setSelections] = useState({});
    const [filteredInventorySims, setFilteredInventorySims] = useState([]);
    const handleSelect = (simId, option, type) => {
      setSelections(prev => ({
        ...prev,
        [simId]: {
          ...prev[simId],
          [type]: option,
          sold: prev[simId]?.sold || false  
        }
      }));
    };
   
    const handleSell = (simId, activatedDate) => {
      const simSelections = selections[simId];
      if (!simSelections || !simSelections.product || !simSelections.duration) {
        console.log("No selections made yet"); // Log if selections are incomplete
        return; // Prevent execution of the sell function
      }
      if (!simSelections.sold) {
        const simCard = allSims.find(sim => sim.simId === simId);
        console.log(allSims)
        const currentIndexes = simCard.tags
        .map(tag => {
          const match = tag.match(/index=(\d+)/);
          return match ? parseInt(match[1], 10) : 0;
        })
        .filter(index => !isNaN(index));
  
      const highestIndex = currentIndexes.length > 0 ? Math.max(...currentIndexes) : 0;
      const newIndex = highestIndex + 1;
      const infoTags = [
        `reseller=${user[3]}#index=${newIndex}`,
        `duration=${simSelections.duration.dayNumber}#index=${newIndex}`,
        `offer=${simSelections.product.name}#index=${newIndex}`,
        `activatedDate=${activatedDate}#index=${newIndex}`
      ];
        sellSim(simId, simSelections.product.id, simSelections.duration.dayNumber, infoTags);
        setSelections(prev => ({
          ...prev,
          [simId]: {
              ...prev[simId],
              sold: true  
          }
        }));
        setTimeout(() => {
          setFilteredInventorySims(currentSims => currentSims.filter(s => s.simId !== simId));
        }, 2000); 
      } else {
        console.log("SIM is already sold.");
      }
    };
    useEffect(() => {
      const filtered = allSims.filter(sim => 
        sim.status === "INACTIVE" &&
        sim.tags && !sim.tags.some(tag => tag.includes("Sold")) && (
          (sim.msisdn && sim.msisdn.toString().toLowerCase().includes(searchTerm.toLowerCase())) || 
          (sim.eid && sim.eid.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
          (sim.offer && sim.offer.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
          (sim.tags && sim.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase())))
        )
      );
      setFilteredInventorySims(filtered);
    }, [allSims, searchTerm]);
    useEffect(() => {
      setInventoryCount(filteredInventorySims.length)
    }, [filteredInventorySims.length]);
    return (
      <div className='content-container-component' >
          <div className="messages-container">
            <div className='table-heading-container'>
              <IoReloadCircleSharp size={28} color='black' onClick={handleReload} style={{ cursor: 'pointer'}} className={`reload-div ${isSpinning ? 'spin-animation' : ''}`} />
              <div className="search-container">
              <IoMdSearch size={28} color= '#646272'/>
                <input 
                    type="text" 
                    className="search-input" 
                    placeholder="Search SIM"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            </div>
              <div className='panel-small'>
                <div className='panel-heading'> 
                  <div className='inner-panel-heading'>
                    <div className='header-container-reseller'>
                      <HeadingSecondary text={`MSISDN - SIM EID`} mode={'dark'}/>
                    </div>
                    <div className='header-container-reseller'>
                      <HeadingSecondary text={`Product`} mode={'dark'}/>
                    </div>
                    <div className='header-container-reseller'>
                      <HeadingSecondary text={`Duration`} mode={'dark'}/>
                    </div>
                    <div className='header-container-reseller'>
                      <HeadingSecondary text={`Action`} mode={'dark'}/>
                    </div>
                  </div>
                </div>
                    <div className='panel-content-container'>
                        <div className='sim-heading-container'>
                            <div className='sortby-container'>
                            </div>
                        </div>
                        <div className='scroll-container'>
                            {filteredInventorySims.map(sim => (
                                <div key={sim.eid} className="reseller-block-table">
                                    <div className="sim-info-reseller">
                                      <div className="sim-text-bold">{sim.msisdn}<br/>{sim.eid}
                                        </div>
                                    </div>
                                    <div className='dropdown-container'>
                                      <Dropdown label="Choose an option" options={productOptions} onSelect={(option) => handleSelect(sim.simId, option, 'product')} />
                                    </div>
                                    <div className='dropdown-container'>
                                      <Dropdown label="Choose an option" options={durationOptions} onSelect={(option) => handleSelect(sim.simId, option, 'duration')} />
                                    </div>
                                    <div className='sell-button-container' onClick={() => handleSell(sim.simId, sim.activatedDate)}>
                                        <div className={`${selections[sim.simId]?.sold ? 'loader' : 'sell-block'}`}> 
                                          <div className='sim-text'>{selections[sim.simId]?.sold ? '' : 'Sell'}</div>
                                      </div>

                                    </div>
                                </div>
                            ))}
                        </div>
                        
                    </div>
              </div>
          </div>
      </div>
  );
};

export default ResellerOverviewScreen;