import React, { useState } from 'react';
import './selector.styles.scss';

const Selector = ({ options, accentColor, onSelectionChange }) => {
  const [selectedOption, setSelectedOption] = useState(' ');
  console.log('initial selected option:', selectedOption)

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    if (onSelectionChange) {
      onSelectionChange(option.value); // Pass only the value
    }
  };

  return (
    <div className="selector-container">
      {options.map((option) => (
        <button
          key={option.label}
          className={`selector-option ${selectedOption.label === option.label ? 'selected' : ''}`}
          style={{ backgroundColor: selectedOption.label === option.label ? accentColor : 'transparent' }}
          onClick={() => handleOptionClick(option)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default Selector;
