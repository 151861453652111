// React/Redux imports 
import { Link } from 'react-router-dom';

// Icons
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdCall } from "react-icons/md";
import { MdPermPhoneMsg } from "react-icons/md";
import { FaHourglassEnd } from "react-icons/fa";


// Typography
import HeadingSecondary from '../../../components/typography/headings/heading-secondary/heading-secondary.component';

// Api
import TotalCallsGas from '../../../api/TotalCallsComponent_gas';
import TotalMinutesGas from '../../../api/TotalMinutesComponent';


import '../../../screens/homescreen/homescreen.styles.scss';

const KpiComponent = () => {
  return (
          <div className='home-container'>
            <HeadingSecondary text={`KPI-Portal`} showArrow={true} link={true} linkTo={'/portal-athalos'} mode={'dark'}  secondLineText={"Use this screen to see insights about your KPI's"}/>
            <div className='kpi-tiles-container'>
              <div className='kpi-tile'>
              <div className="traffic-header">
                <div className='tt-title'>Traffic Today</div>
                <Link to={'/portal-athalos'} className="heading-secondary-link">
                  <div className="arrow-icon">
                    <MdKeyboardArrowRight size={32} color='var(--heading-color-dark)'/>
                  </div>
                </Link>
              </div>
                <div className="traffic-stats">
                  <div className="stat-item">
                    <div className="icon-phone">
                      <MdCall size={37} color='var(--dynamic-color)'/>
                    </div>
                    <TotalCallsGas className='stat-value'displayMode={'second'}/>
                    <div className="stat-label">Total Calls</div>
                  </div>
                  <div className="stat-item">
                    <div className="icon-timer">
                      <FaHourglassEnd size={37} color='var(--dynamic-color)'/>
                    </div>
                    <TotalMinutesGas className='stat-value'/>
                    <div className="stat-label">Minutes Called</div>
                  </div>
                  <div className="stat-item">
                    <div className="icon-check">
                      <MdPermPhoneMsg size={37} color='var(--dynamic-color)'/>
                    </div>
                    <TotalCallsGas className='stat-value'displayMode={'first'}/>
                    <div className="stat-label">Calls Answered</div>
                  </div>
                </div>
              </div>     
            </div>
          </div>
  );
};

export default KpiComponent;
