// React/Redux imports 
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStatic } from '../../api/apiFunctions';
import { setAboutData } from '../../redux/staticDataSlice';

// Components
import Sidebar from '../../components/sidebar/sidebar.component';
import HeaderComponent from '../../components/header-component/header.component';

// Typography
import HeadingPrimary from '../../components/typography/headings/heading-primary/heading-primary.component';
import HeadingSecondary from '../../components/typography/headings/heading-secondary/heading-secondary.component';
import Paragraph from '../../components/typography/paragraph/paragraph.component';

import './about.styles.scss';

const About = () => {
  const { about } = useSelector((state) => state.staticData);
  const dispatch = useDispatch();
  const [showSidebarMobile, setShowSidebarMobile] = useState(false);
  const showSidebar = useSelector((state) => state.ui.sidebarOpen)
  const isMobile = window.innerWidth <= 766;
  // Function will fetch data, only if the data state is empty. This will reset after every session. (User closes tab/app and ends session)
  useEffect(() => {
    async function fetchData() {
      getStatic('about').then((res) => dispatch(setAboutData(res)));
    }

    if (!about) {
      fetchData();
    }
  }, [about, dispatch]);

  return (
    <div className='header-layout'>
    <div className="bottom-nav-container">
      <HeaderComponent showSidebarMobile={showSidebarMobile}
      setShowSidebarMobile={setShowSidebarMobile} />
    </div>
    <div className='homescreen-layout'>
    {isMobile ? (
      <div className={`sidebar-container-mobile ${showSidebarMobile ? 'sidebar-visible' : ''}`}>
        <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
          <Sidebar />
        </div>
      </div>
    ) : (
      <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
        <Sidebar />
      </div>
    )}
        <div className="content-container">
          <HeadingPrimary text={about.title ?? 'About'} />

          <div className="about-container">
            <HeadingSecondary text={'Vision and Mission'} />
            <Paragraph cname={'about-text'} text={about.paragraph_1 ?? 'Loading...'} />
            <Paragraph cname={'version-text'} text={'Build version v4.1'}/>
            <Paragraph cname={'version-text'} text={'Published on 19-02-2024'}/>
            <div className="about-img-container">
              <img src={about.image_1} alt="about-img" className="about-img" />
            </div>

            <Paragraph cname={'about-text'} text={about.paragraph_2} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
