import React, { useState, useEffect, useContext } from "react"
import { DataContext } from "./DataContext"
import LineGraph from '../components/graphs/Graph_mt_gas'

function MonthlyStatsgas({ className, selectedRange }) {
  const [dataPoints, setDataPoints] = useState([])
  const { records, dailyrecords } = useContext(DataContext)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (records && records.length > 0) {
      let newPoints = []
      let dayCounter = 1
      let filteredrecords

      if (selectedRange === "Today") {
        filteredrecords = dailyrecords
      } else if (selectedRange === "Last 7 days") {
        filteredrecords = records.slice(0, 7)
      } else if (selectedRange === "Last Month") {
        filteredrecords = records
      } else {
        filteredrecords = [records[0]]
      }

      filteredrecords.reverse().forEach(record => {
        const parts = record.split(">>")
        let calls = 0
        let durm = 0
        parts.forEach(part => {
          const [key, value] = part.split("=")
          if (key === "calls") {
            calls = parseInt(value, 10)
          } else if (key === "durm") {
            durm = parseInt(value, 10)
          }
        })
        newPoints.push({
          day: dayCounter,
          calls,
          durm
        })

        dayCounter++
      })

      setDataPoints(newPoints)
    }
  }, [selectedRange, records])

  return (
    <div className={className}>
      <LineGraph data={dataPoints} selectedRange={selectedRange} />
    </div>
  )
}

export default MonthlyStatsgas
