import React, { useState, forwardRef } from "react"
import Dropdown from "react-bootstrap/Dropdown"
import Form from "react-bootstrap/Form"
import ButtonGroup from "react-bootstrap/ButtonGroup"
import  './navbar.styles.scss'

const countries = [
  { name: "All Countries", code: "All Countries" },
  { name: "Andorra", code: "AD" },
  { name: "Albania", code: "AL" },
  { name: "Aruba", code: "AW" },
  { name: "Bahrain", code: "BH" },
  { name: "Botswana", code: "BW" },
  { name: "Democratic Republic of the Congo", code: "CD" },
  { name: "Ivory Coast", code: "CI" },
  { name: "Cook Islands", code: "CK" },
  { name: "Cameroon", code: "CM" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cuba", code: "CU" },
  { name: "Germany", code: "DE" },
  { name: "Denmark", code: "DK" },
  { name: "Spain", code: "ES" },
  { name: "France", code: "FR" },
  { name: "United Kingdom", code: "GB" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Mexico", code: "MX" },
  { name: "Mozambique", code: "MZ" },
  { name: "Netherlands", code: "NL" },
  { name: "French Polynesia", code: "PF" },
  { name: "Philippines", code: "PH" },
  { name: "Pakistan", code: "PK" },
  { name: "Poland", code: "PL" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Portugal", code: "PT" },
  { name: "Romania", code: "RO" },
  { name: "Russia", code: "RU" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Sao Tome", code: "ST" },
  { name: "Chad", code: "TD" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Turkey", code: "TR" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Ukraine", code: "UA" },
  { name: "United States of America", code: "US" },
  { name: "South Africa", code: "ZA" }
]

const providers = [
  "Athalos CXS 88251",
  "Athalos CXS 883100",
  "Athalos Main",
  "Athalos MS Teams",
  "Athalos Operator",
  "Athalos OS SMS",
  "Athalos Partner",
  "Athalos Sms Dump",
  "Athalos Sms Euro",
  "Athalos Sms Usd",
  "Athalos Test Room",
  "Athalos Test SMS/Voice",
  "BICS SA",
  "Bridgevoice Inc",
  "Broadband Telecom Inc",
  "BTS Inc",
  "Carrier2 Network BV",
  "CSR NGO PK",
  "DID Logic",
  "Digicel Croup",
  "iBasis Global Inc",
  "Lyca Mobile",
  "MCXess BV",
  "Monty Mobile",
  "Monty Mobile (A2P)",
  "MTN ClobalConncct Ltd",
  "Number Paused",
  "PRS Telecom B.V.",
  "Roxytel Ltd",
  "Stelecom Solutions (Lanck)",
  "Telbo Bonaire",
  "Tclin",
  "VoiceBuy",
  "Vox Carrier Ltd",
  "WIS Telecom SRL",
  "WIS Telecor•n SRL (SMS VVS)",
  "Zain Global"
]

const operators = [
  "All Operators",
  "Monty Mobile (A2P) XQ",
  "Stelecom Solutions (Lanck) AH",
  "Telin XQ",
  "iBasis Global Inc AH",
  "iBasis Global Inc XQ"
]

const CustomMenu = forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("")

    const filterChildren = child => {
      if (
        React.isValidElement(child) &&
        typeof child.props.children === "string"
      ) {
        return child.props.children
          .toLowerCase()
          .startsWith(value.toLowerCase())
      }
      return false
    }

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={e => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(filterChildren)}
        </ul>
      </div>
    )
  }
)

function NavbarGas({ onDropdownChange, onDropdownCountryChange, onMapChange }) {
  return (
    <ButtonGroup>
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-date" className='buttondate'>
          Date
        </Dropdown.Toggle>
        <Dropdown.Menu >
          <Dropdown.Item eventKey="1" onClick={() => onDropdownChange("Today")}>Today</Dropdown.Item>
          <Dropdown.Item eventKey="2" onClick={() => onDropdownChange("Last 7 days")}>Last 7 days</Dropdown.Item>
          <Dropdown.Item eventKey="3" onClick={() => onDropdownChange("Last Month")}>Last Month</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-country" className='buttondate'>
          Country
        </Dropdown.Toggle>
        <Dropdown.Menu
          as={CustomMenu}
          className='super-colors'
          style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}
        >
          {countries.map((countryObj, index) => (
            <Dropdown.Item
              eventKey={`${index}`}
              key={countryObj.code}
              onClick={() => onDropdownCountryChange(countryObj.code)}
            >
                {`${countryObj.name} (${countryObj.code})`}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown>
        <Dropdown.Toggle  variant="success" id="dropdown-date-2" className='buttondate'>
          Operator
        </Dropdown.Toggle>
        <Dropdown.Menu
          as={CustomMenu}
          className='super-colors'
          style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}
        >
          {operators.map((operator, index) => (
            <Dropdown.Item eventKey={`${index}`} key={operator}  onClick={() => onMapChange(operator)}>
              {operator}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </ButtonGroup>
  )
}

export default NavbarGas
