import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStatic } from '../../api/apiFunctions';
import Sidebar from '../../components/sidebar/sidebar.component';
import HeadingPrimary from '../../components/typography/headings/heading-primary/heading-primary.component';
import HeadingSecondary from '../../components/typography/headings/heading-secondary/heading-secondary.component';
import Paragraph from '../../components/typography/paragraph/paragraph.component';
import { setContactData } from '../../redux/staticDataSlice';
import HeaderComponent from '../../components/header-component/header.component';
import '../../App.scss';
import './contact.styles.scss';

const Contact = () => {
  const { contact } = useSelector((state) => state.staticData);
  const dispatch = useDispatch();
  const [showSidebar, setShowSidebar] = useState(true);
  const [user1Messages, setUser1Messages] = useState([]);
  const [user2Messages, setUser2Messages] = useState([]);
  const [user1Input, setUser1Input] = useState('');
  const [user2Input, setUser2Input] = useState('');
  const [showSidebarMobile, setShowSidebarMobile] = useState(false);
  const isMobile = window.innerWidth <= 766;
  const sendMessageFromUser1 = () => {
    setUser2Messages((prevMessages) => {
      const updatedMessages = [...prevMessages, user1Input];
      localStorage.setItem('user2Messages', JSON.stringify(updatedMessages));
      return updatedMessages;
    });
    setUser1Input('');
  };
  
  const sendMessageFromUser2 = () => {
    setUser1Messages((prevMessages) => {
      const updatedMessages = [...prevMessages, user2Input];
      localStorage.setItem('user1Messages', JSON.stringify(updatedMessages));
      return updatedMessages;
    });
    setUser2Input('');
  };





  // Function to toggle the sidebar
  const toggleSidebar = () => setShowSidebar(!showSidebar);

  // Fetch contact data from the backend
  useEffect(() => {
    async function fetchData() {
      getStatic('contact').then((res) => dispatch(setContactData(res)));
    }

    if (!contact) {
      fetchData();
    }
  }, [contact, dispatch]);

  // Load saved messages from localStorage
  useEffect(() => {
    const savedUser1Messages = JSON.parse(localStorage.getItem('user1Messages')) || [];
    const savedUser2Messages = JSON.parse(localStorage.getItem('user2Messages')) || [];
    setUser1Messages(savedUser1Messages);
    setUser2Messages(savedUser2Messages);
  }, []);

  // // Save messages to localStorage whenever they change
  // useEffect(() => {
  //   localStorage.setItem('user1Messages', JSON.stringify(user1Messages));
  //   localStorage.setItem('user2Messages', JSON.stringify(user2Messages));
  // }, [user1Messages, user2Messages]);


  return (
    <div className='header-layout'>
      <div className="bottom-nav-container">
        <HeaderComponent showSidebarMobile={showSidebarMobile}
        setShowSidebarMobile={setShowSidebarMobile} />
      </div>
      <div className='contactscreen-layout'>
      {isMobile ? (
        <div className={`sidebar-container-mobile ${showSidebarMobile ? 'sidebar-visible' : ''}`}>
          <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
            <Sidebar />
          </div>
        </div>
      ) : (
        <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
          <Sidebar />
        </div>
      )}
      <div className='content-container'>
      <div className="user1">
        <h2>User 1 Screen</h2>
        {/* Section 1: User 1 received messages */}
        <div className="messages">
          {user1Messages.map((msg, index) => (
            <p key={index}>{msg}</p>
          ))}
        </div>
        {/* Section 2: User 1 send field */}
        <div className="send">
          <input
            type="text"
            value={user1Input}
            onChange={(e) => setUser1Input(e.target.value)}
          />
          <button onClick={sendMessageFromUser1}>Send</button>
        </div>
      </div>

      <div className="user2">
        <h2>User 2 Screen</h2>
        {/* Section 3: User 2 received messages */}
        <div className="messages">
          {user2Messages.map((msg, index) => (
            <p key={index}>{msg}</p>
          ))}
        </div>
        {/* Section 4: User 2 send field */}
        <div className="send">
          <input
            type="text"
            value={user2Input}
            onChange={(e) => setUser2Input(e.target.value)}
          />
          <button onClick={sendMessageFromUser2}>Send</button>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Contact;
