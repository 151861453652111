import React, { useEffect } from "react"
import '../../screens/athalosscreen/athalosscreen.styles.scss'
import L from "leaflet"
import "leaflet/dist/leaflet.css"
import { parseCSV } from "./MapData"

const MapComponentGas = ({ mapChange }) => {

  useEffect(() => {
    let map

    try {
      const existingMap = L.DomUtil.get('mapid')
      if (existingMap !== null) {
        existingMap._leaflet_id = null
      }

      map = L.map("mapid", {
        center: [48.8566, 2.3522],
        zoom: 1,
        maxZoom: 6,
        minZoom: 1,
        dragging: true,
        touchZoom: true,
        doubleClickZoom: false,
        scrollWheelZoom: false,
        boxZoom: false,
        keyboard: false,
        zoomControl: true
      })

      L.tileLayer("https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png", {
        attribution: 'Map data: &copy; <a href="http://www.openseamap.org">OpenSeaMap</a> contributors'
      }).addTo(map)

      let highlightedCountries = []
      const selectedCountries = []
      const countriesDataMap = {}

      function onEachCountryLayer(feature, layer) {
        const countryName = feature?.properties?.name

        if (highlightedCountries.includes(countryName)) {
          layer.on({
            mouseover: function(event) {
              layer = event.target
              layer.setStyle({
                weight: 3,
                color: "#8649BF"
              })
            },
            mouseout: function(event) {
              layer = event.target
              if (!selectedCountries.includes(layer)) {
                layer.setStyle({
                  weight: 0.5,
                  color: "#ffffff"
                })
              }
            },
            click: function(event) {
              layer = event.target
              var index = selectedCountries.indexOf(layer)

              if (index === -1) {
                if (selectedCountries.length < 1) {
                  layer.setStyle({
                    fillColor: "#8649BF",
                    weight: 4,
                    color: "#8649BF"
                  })
                  selectedCountries.push(layer)
                }
              } else {
                layer.setStyle({
                  fillColor: "#8649BF",
                  weight: 1,
                  color: "#ffffff"
                })
                selectedCountries.splice(index, 1)
                map.closePopup()
              }

              var popupContent = `
                <div class="animated-popup-content">
                    <p>${countryName}<br />
                      Current operators:<br />
                    ${countriesDataMap[countryName].diff_providers}</p>
                </div>
                `

              var popup = L.popup({
                autoPan: true,
                className: "animated-popup",
                closeButton: false,
                autoClose: true,
                closeOnClick: true
              })
                .setLatLng(event.latlng)
                .setContent(popupContent)

              popup.openOn(map)
            }
          })
        }

        if (highlightedCountries.includes(feature.properties.name)) {
          layer.setStyle({
            fillColor: "#8649bf",
            weight: 0.5,
            color: "#ffffff"
          })
        }
      }

      fetch("map/map_data.csv")
        .then(response => response.text())
        .then(data => {
          const countriesData = parseCSV(data)
          let filteredCountries

          if (mapChange === 'All Operators') {
            filteredCountries = countriesData
          } else {
            filteredCountries = countriesData.filter(country => 
              country.diff_providers.includes(mapChange)
            )
          }

          highlightedCountries = filteredCountries.map(entry => {
            countriesDataMap[entry.country] = {
              diff_providers: entry.diff_providers
            }
            return entry.country
          })

          fetch("map/countries.geo.json")
            .then(response => response.json())
            .then(geojsonData => {
              L.geoJSON(geojsonData, {
                style: function(feature) {
                  if (
                    feature &&
                    feature.properties &&
                    highlightedCountries.includes(feature.properties.name)
                  ) {
                    return {
                      color: "#ffffff",
                      fillColor: "#8649BF",
                      fillOpacity: 1,
                      weight: 1
                    }
                  } else {
                    return {
                      color: "#ffffff",
                      fillColor: "#ffffff",
                      fillOpacity: 1,
                      weight: 0.5
                    }
                  }
                },
                onEachFeature: onEachCountryLayer
              }).addTo(map)
            })
        })
    } catch (error) {
      console.error("An error occurred while initializing the map:", error)
    }

    return () => {
      if (map) {
        map.remove()
      }
    }
  }, [mapChange])

  return <div id="mapid" className='map'></div>
}

export default MapComponentGas
