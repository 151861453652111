
import { useState } from 'react'; 
import './settingsscreen.component';
import HeadingPrimary from '../../components/typography/headings/heading-primary/heading-primary.component';
import HeadingSecondary from '../../components/typography/headings/heading-secondary/heading-secondary.component';
import Sidebar from '../../components/sidebar/sidebar.component';
import { useSelector } from 'react-redux';
import './settingsscreen.styles.scss';
import HeaderComponent from '../../components/header-component/header.component';
import { MdApartment } from "react-icons/md";
import { MdAccountBox } from "react-icons/md";
import { Md3P } from "react-icons/md";
import { MdReceiptLong } from "react-icons/md";
import { MdBuild } from "react-icons/md";
import { MdShield } from "react-icons/md";
import { MdHelp } from "react-icons/md";
import InputField from '../../components/input-field/inputfield.component';
import CompanyInformation from './setting-pages/companyinformation/companyinformation.component';
import ProfileInformation from './setting-pages/profileinformation/profileinformation.component';
import Customization from './setting-pages/customization/customization.component';


const Settingsscreen = () => {
  const [activeSetting, setActiveSetting] = useState('company');
  const isMobile = window.innerWidth <= 766;
  const [showSidebarMobile, setShowSidebarMobile] = useState(false);
    const renderSetting = () => {
        switch (activeSetting) {
            case 'company':
                return <CompanyInformation />;
            case 'profile':
                return <ProfileInformation />;
            case 'user':
                return <ProfileInformation />;
            case 'billing':
              return <ProfileInformation />;
            case 'custom':
              return <Customization />;
            case 'security':
              return <ProfileInformation />;
            case 'help':
              return <ProfileInformation />;
            default:
                return <CompanyInformation />;
        }
    };
  const showSidebar = useSelector((state) => state.ui.sidebarOpen)
  return (
    <div className='header-layout'>
    <div className="bottom-nav-container">
      <HeaderComponent showSidebarMobile={showSidebarMobile}
      setShowSidebarMobile={setShowSidebarMobile} />
    </div>
    <div className='portalscreen-layout'>
    {isMobile ? (
      <div className={`sidebar-container-mobile ${showSidebarMobile ? 'sidebar-visible' : ''}`}>
        <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
          <Sidebar />
        </div>
      </div>
    ) : (
      <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
        <Sidebar />
      </div>
    )}
       <div className={'content-container'}>   
      <div className='cols-parent'>
        <div className='col-1'>
        <HeadingPrimary text={'Settings'} />
        <div className='col-1-container'>
          <div className={activeSetting === 'company' ? 'row-1-active' : 'row-1'} onClick={() => setActiveSetting('company')}>
            <MdApartment size={22} className='icon'/>
            <div className='settings-title'> Company Information</div>
          </div>
          <div className={activeSetting === 'profile' ? 'row-1-active' : 'row-1'} onClick={() => setActiveSetting('profile')}>
            <MdAccountBox  size={22} className='icon'/>
            <div className='settings-title'> Profile Information</div>
          </div>
          <div className={activeSetting === 'user' ? 'row-1-active' : 'row-1'} onClick={() => setActiveSetting('user')}>
            <Md3P size={22} className='icon'/>
            <div className='settings-title'> User Roles</div>
          </div>
          <div className={activeSetting === 'billing' ? 'row-1-active' : 'row-1'} onClick={() => setActiveSetting('billing')}>
            <MdReceiptLong  size={22} className='icon'/>
            <div className='settings-title'>Billing plans</div>
          </div>
          <div className={activeSetting === 'custom' ? 'row-1-active' : 'row-1'} onClick={() => setActiveSetting('custom')}>
            <MdBuild  size={22} className='icon'/>  
            <div className='settings-title' >Customization</div>
          </div>
          <div className={activeSetting === 'security' ? 'row-1-active' : 'row-1'} onClick={() => setActiveSetting('security')}>
            <MdShield  size={22} className='icon'/>
            <div className='settings-title'>Security</div>
          </div>
          <div className={activeSetting === 'help' ? 'row-1-active' : 'row-1'} onClick={() => setActiveSetting('help')}>
            <MdHelp  size={22} className='icon'/>
            <div className='settings-title'>Help & Support</div>
          </div>
        </div>
        </div>
          {renderSetting()}
        </div>
        </div>
      </div>
      </div>
  );
}

export default Settingsscreen;
