import React, { useState, useEffect, useContext } from "react"
import { DataContext } from "./DataContext"

function TotalMinutesGas({ className, selectedRange }) {
  const [totalDuration, setTotalDuration] = useState(null)
  const { records, dailyrecords } = useContext(DataContext)

  useEffect(() => {
    if (records && records.length > 0) {
      let filteredrecords
      if (selectedRange === "Today") {
        filteredrecords = dailyrecords
      } else if (selectedRange === "Last 7 days") {
        filteredrecords = records.slice(0, 7)
      } else if (selectedRange === "Last Month") {
        filteredrecords = records
      } else {
        filteredrecords = dailyrecords
      }

      let calculatedTotalDuration = 0

      filteredrecords.forEach(record => {
        const parts = record.split(">>")
        parts.forEach(part => {
          const [key, value] = part.split("=")
          if (key === "durm") {
            calculatedTotalDuration += parseInt(value, 10)
          }
        })
      })

      setTotalDuration(calculatedTotalDuration)
    }
  }, [selectedRange, records])

  return (
    <div className={className}>
      <div>
        {totalDuration !== null ? Math.floor(totalDuration) : 0}
      </div>
    </div>
  )
}

export default TotalMinutesGas
