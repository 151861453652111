import { FiList } from 'react-icons/fi';
import { CgMenuGridR } from 'react-icons/cg';

export const filterItems = [
    {
      icon: <FiList strokeWidth="3"/>,
      cName: 'filter-item',
    },
    {
      icon: <CgMenuGridR />,
      cName: 'filter-item',
    },
  ];