
import { useState } from 'react'; 
import '../managescreen/managescreen.styles.scss';
import Sidebar from '../../components/sidebar/sidebar.component';
import HeaderComponent from '../../components/header-component/header.component';
import { useSelector } from 'react-redux';
import HeadingPrimary from '../../components/typography/headings/heading-primary/heading-primary.component';
import Simsimage from '../../assets/img/image.png'
import image1  from '../../assets/img/image1.png'


const ManageScreen = () => {

  const showSidebar = useSelector((state) => state.ui.sidebarOpen)
  const isMobile = window.innerWidth <= 766;  
  const [showSidebarMobile, setShowSidebarMobile] = useState(false);
  return (
    <div className='header-layout'>
        <div className="bottom-nav-container">
          <HeaderComponent showSidebarMobile={showSidebarMobile}
          setShowSidebarMobile={setShowSidebarMobile} />
        </div>
        <div className='portalscreen-layout'>
        {isMobile ? (
          <div className={`sidebar-container-mobile ${showSidebarMobile ? 'sidebar-visible' : ''}`}>
            <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
              <Sidebar />
            </div>
          </div>
        ) : (
          <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
            <Sidebar />
          </div>
        )}
        <div className={'content-container'}>   
              <HeadingPrimary text={'Under development...:'}/>
              <img src={Simsimage} alt="sims_image" />
              <img src={image1} alt="sims_image" />
          </div>
        </div>
      </div>
  );
}

export default ManageScreen;
