import React from 'react';

import './paragraph.styles.scss';

const Paragraph = (
  (
    {
      text,
      cname,
      size,
      style,
    },
  ) => (
    <p
      className={`${cname} paragraph`}
      style={{
        fontSize: size,
        ...style
      }}
    >
      {text}
    </p>
  )
);

export default Paragraph;
