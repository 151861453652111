import './voipscreen.styles.scss';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Sidebar from '../../components/sidebar/sidebar.component';
import HeaderComponent from '../../components/header-component/header.component';
import HeadingPrimary from '../../components/typography/headings/heading-primary/heading-primary.component';
import VideoChat from '../../components/videochat/videochat.component';


const VoipScreen = () => {
    const showSidebar = useSelector((state) => state.ui.sidebarOpen)
    const isMobile = window.innerWidth <= 766;
    const [showSidebarMobile, setShowSidebarMobile] = useState(false);
  
  return (
    <div className='header-layout'>
    <div className="bottom-nav-container">
      <HeaderComponent showSidebarMobile={showSidebarMobile}
      setShowSidebarMobile={setShowSidebarMobile} />
    </div>
    <div className='portalscreen-layout'>
    {isMobile ? (
      <div className={`sidebar-container-mobile ${showSidebarMobile ? 'sidebar-visible' : ''}`}>
        <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
          <Sidebar />
        </div>
      </div>
    ) : (
      <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
        <Sidebar />
      </div>
    )}
     <div className={'content-container'}>
        <div className="messages-container">
          <HeadingPrimary text={'OWN VoIp'} />
          <VideoChat/>
        </div>
      </div>
    </div>
    </div>
  );
};

export default VoipScreen;