import './resellerproductscreen.styles.scss';
import { useSelector } from 'react-redux';
import Sidebar from '../../components/sidebar/sidebar.component';
import HeaderComponent from '../../components/header-component/header.component';
import HeadingPrimary from '../../components/typography/headings/heading-primary/heading-primary.component';
import { useContext, useState, useEffect } from 'react';
import { SimContext } from '../../api/SimContext';
import HeadingSecondary from '../../components/typography/headings/heading-secondary/heading-secondary.component';
import { IoMdSearch } from 'react-icons/io';
import Dropdown from '../../components/dropdown/dropdown.component';
import { HiLightningBolt } from "react-icons/hi";

const ResellerProductScreen = ({setProductCount}) => {
    const showSidebar = useSelector((state) => state.ui.sidebarOpen);
    const {  offers, activateSim } = useContext(SimContext);
    const [searchTerm, setSearchTerm] = useState('');
    const productOptions = offers.map(offer => offer.name);
    const productDescription = offers.map(offer => offer.description);
    const durationOptions = ['1 day', '3 day', '7 days']
    const [selections, setSelections] = useState({});
    const isMobile = window.innerWidth <= 766;
    const [showSidebarMobile, setShowSidebarMobile] = useState(false);
    const handleSelect = (simId, option, type) => {
      setSelections(prev => ({
        ...prev,
        [simId]: {
          ...prev[simId],
          [type]: option
        }
      }));
    };

    const handleSell = (simId) => {
      activateSim(simId)
    };

    useEffect(() => {
      setProductCount(offers.length)
    }, [offers.length]);
    return (
          <div className={'content-container-component'}>
              <div className="messages-container">
                <div className='product-margin'>
                  <div className='panel-small-products'>
                    <div className='panel-heading'> 
                      <div className='inner-panel-heading-products'>
                        <div className='header-container-reseller'>
                          <HeadingSecondary text={`Description`} mode={'dark'}/>
                        </div>
                        <div className='header-container-reseller'>
                          <HeadingSecondary text={`Product`}mode={'dark'}/>
                        </div>
                        {/* <div className='header-container-reseller'>
                          <HeadingSecondary text={`Duration`}/>
                        </div>
                        <div className='header-container-reseller'>
                          <HeadingSecondary text={`Sell`}/>
                        </div> */}
                      </div>
                    </div>
                        <div className='panel-content-container'>
                            <div className='sim-heading-container'>
                                <div className='sortby-container'>
                                </div>
                            </div>
                            <div className='scroll-container'>
                                {offers.map(offer=> (
                                    <div key={offer.description} className="reseller-block-products">
                                        <div className="sim-info-products">                                          
                                          <HiLightningBolt size={22} color='var(--dynamic-color)' className='icon'/>
                                            <div className="sim-text-bold">{offer.description}</div>
                                        </div>

                                        <div className='dropdown-container-products'>
                                            <div className="sim-text-bold">{offer.name}</div>
                                          {/* <Dropdown label="Choose an option" options={productOptions} onSelect={(option) => handleSelect(sim.simId, option, 'product')} /> */}
                                        </div>
                                        {/* <div className='dropdown-container'>
                                          <Dropdown label="Choose an option" options={durationOptions} onSelect={(option) => handleSelect(sim.simId, option, 'duration')} />
                                        </div>
                                        <div className='sell-button-container' onClick={() => handleSell(sim.simId)}>
                                          <div className="sell-block">
                                              <div className='sim-text'>Sell</div>
                                          </div> */}
                                        {/* </div> */}
                                    </div>
                                ))}
                            </div>
                        </div>
                  </div>
              </div>
          </div>
          </div>
  );
};

export default ResellerProductScreen;