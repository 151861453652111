import './companyinformation.styles.scss';
import InputField from '../../../../components/input-field/inputfield.component';


const CompanyInformation = () => {
  
  return (
        <div className='col-2'>
          <div className='col-2-container'>
            <div className='col-2-row-1'>
              <div className='col-2-heading'> Company Information</div>
              <InputField label='Company Name' placeholder='e.g. Athalos' id='companyName'/>
            </div>
            <div className='col-2-row-2'>
              <div className='col-2-heading'> Billing Adress</div>
              <div className='input-container'>
                <div className='row-2-input-container'>
                  <InputField label='Address' placeholder='e.g. Boeingavenue 8' id='address'/>
                  <InputField label='Zip code' placeholder='e.g. 1119PB' id='zipcode'/>
                  <InputField label='Country' placeholder='e.g. Netherlands' id='country'/>
                </div>
              </div>
            </div>
            <div className='col-2-row-3'> 
              <div className='col-2-heading'>Contact Information</div>
                <div className='row-3-input-container'>
                  <InputField label='Email' placeholder='e.g. john@doe.com' id='emailcompany'/>
                  <InputField label='Phone Number' placeholder='e.g. +31 (1) 23456789' id='email'/>
                </div>
            </div>
            <div className='col-2-row-4'>
              <button className='discard'>Discard</button>
              <button className='saveChanges'>Save Changes</button>
            </div>
          </div>
          </div>
  );
}

export default CompanyInformation;
