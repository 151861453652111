import { configureStore, combineReducers } from '@reduxjs/toolkit';
import sessionStorage from 'redux-persist/es/storage/session';
import { persistReducer } from 'redux-persist';
import { persistStore } from 'redux-persist';

// import reducers
import userReducer from './userSlice';
import defaultServicesReducer from './defaultServicesSlice';
import gridListReducer from './gridListFilter';
import uiReducer from './uiSlice';
import staticDataReducer from './staticDataSlice';
import themeReducer from './themeSlice'


const reducers = combineReducers({
  user: userReducer,
  defaultServices: defaultServicesReducer,
  gridList: gridListReducer,
  staticData: staticDataReducer,
  ui: uiReducer,
  theme: themeReducer,
});

const persistConfig = {
  key: 'root',
  storage: sessionStorage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [],
});

export const persistor = persistStore(store);
