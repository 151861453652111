import './resellersimscreen.styles.scss';
import { useSelector } from 'react-redux';
import Sidebar from '../../components/sidebar/sidebar.component';
import HeaderComponent from '../../components/header-component/header.component';
import HeadingPrimary from '../../components/typography/headings/heading-primary/heading-primary.component';
import { useContext, useState, useEffect } from 'react';
import { SimContext } from '../../api/SimContext';
import HeadingSecondary from '../../components/typography/headings/heading-secondary/heading-secondary.component';
import { IoMdSearch } from 'react-icons/io';
import Dropdown from '../../components/dropdown/dropdown.component';
import { IoReloadCircleSharp } from 'react-icons/io5';
const ResellerSoldSimsScreen = ({setSoldCount}) => {
    const { allSims, simToInv, reloadKey, setReloadKey} = useContext(SimContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredActiveSims, setFilteredActiveSims] = useState([]);
    const [selections, setSelections] = useState({});
    const [isSpinning, setIsSpinning] = useState(false);
    const handleReload = () => {
      setIsSpinning(true);
      setTimeout(() => {
        setReloadKey(reloadKey + 1);
        setIsSpinning(false);
      }, 1000); // Match this duration with the CSS animation duration
    };
    useEffect(() => {
      const filtered = allSims.filter(sim => {
        // Include only SIMs that match the exclusion conditions
        if ((sim.status === "ACTIVE" && sim.offer !== "NO_OFFER" && sim.tags && sim.tags.some(tag => tag.includes("Sold")))) {
          
          // Keep the search functionality intact
          return (
            (sim.msisdn && sim.msisdn.toString().toLowerCase().includes(searchTerm.toLowerCase())) || 
            (sim.eid && sim.eid.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
            (sim.offer && sim.offer.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
            (sim.tags && sim.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase())))
          );
        }
        
        return false; // Exclude other SIMs
      });
    
      setFilteredActiveSims(filtered);
    }, [allSims, searchTerm]);

    // const handleSelect = (simId, option, type) => {
    //   setSelections(prev => ({
    //     ...prev,
    //     [simId]: {
    //       ...prev[simId],
    //       [type]: option,
    //       inv: prev[simId]?.inv || false  
    //     }
    //   }));
    // };
    useEffect(() => {
      setSoldCount(filteredActiveSims.length)
    }, [filteredActiveSims.length]);
   
    return (
      <div className={'content-container-component'}>
          <div className="messages-container">
          <div className='table-heading-container'>
              <IoReloadCircleSharp size={28} color='black' onClick={handleReload} style={{ cursor: 'pointer'}} className={`reload-div ${isSpinning ? 'spin-animation' : ''}`} />
              <div className="search-container">
              <IoMdSearch size={28} color= '#646272'/>
                <input 
                    type="text" 
                    className="search-input" 
                    placeholder="Search SIM"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            </div>
              <div className='panel-small'>
                <div className='panel-heading'> 
                  <div className='inner-panel-heading'>
                    <div className='header-container-reseller'>
                      <HeadingSecondary text={`MSISDN - SIM EID`} mode={'dark'}/>
                    </div>
                    <div className='header-container-reseller'>
                      <HeadingSecondary text={`Product`}mode={'dark'}/>
                    </div>
                    <div className='header-container-reseller'>
                      <HeadingSecondary text={`Duration`}mode={'dark'}/>
                    </div>
                    <div className='header-container-reseller'>
                      <HeadingSecondary text={`Action`}mode={'dark'}  />     {/* subText={'Set to inventory'} */}
                    </div>
                  </div>
                </div>
                    <div className='panel-content-container'>
                        <div className='sim-heading-container'>
                            <div className='sortby-container'>
                            </div>
                        </div>
                        <div className='scroll-container'>
                            {filteredActiveSims.map(sim => (
                                <div key={sim.eid} className="reseller-block-table">

                                    <div className="sim-info-reseller">
                                      <div className="sim-text-bold">{sim.msisdn}<br/>{ sim.eid}</div>
                                      {/* <div className="sim-text-bold">{"MSISDN: " + sim.msisdn}</div><br/><div className="sim-text-bold-smaller">{"EID: " + sim.eid}</div> */}
                                      {/* <div className="sim-text-bold">{sim.eid}</div> */}
                                      {/* <div className="sim-text-bold-smaller">{sim.msisdn}</div> */}
                                    </div>
                                    
                                    <div className="sim-info-reseller">
                                      <div className="sim-text-bold">{sim.offer}</div>
                                    </div>

                                    {/* <div className='dropdown-container'>
                                      <div className="sim-text-bold">{sim.name}</div>                                        </div> */}
                                    <div className='dropdown-container'>
                                      <div className="duration-block">
                                            <div className='sim-text'>{sim.activatedDate}</div>
                                        </div>
                                    </div>
                                    <div className='sell-button-container' onClick={() => {
                                            if (!selections[sim.simId]?.sold) {
                                                simToInv(sim.simId);
                                                setSelections(prev => ({
                                                  ...prev,
                                                  [sim.simId]: {
                                                      ...prev[sim.simId],
                                                      inv: true  
                                                  }
                                              }));
                                              setTimeout(() => {
                                                setFilteredActiveSims(currentSims => currentSims.filter(s => s.simId !== sim.simId));
                                            }, 2000); 
                                            } else {
                                                console.log("SIM is already out of inventory."); 
                                            }
                                        }}>
                                        <div className={`${selections[sim.simId]?.inv ? 'loader' : 'sell-block'}`}> 
                                            <div className='sim-text'>{selections[sim.simId]?.inv ? '' : 'Set Inventory'}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
              </div>
          </div>
      </div>
  );
};

export default ResellerSoldSimsScreen;