import {React, useState} from 'react';
import { useSelector } from 'react-redux';
import { MdMenu } from "react-icons/md";
import FormButton from '../form-button/form-button.component';
import { IoMdCart } from "react-icons/io";
import './headercomponent.styles.scss'
import AthalosLogo from '../../assets/img/athalos-logo.png';
import MediamarktLogo from '../../assets/img/mediamarkt-logo.webp'
import TTLogo from '../../assets/img/tt-logo.webp';
import TTLogoWide from '../../assets/img/TTLogo-transparant.png';




const HeaderComponent = ({showSidebarMobile, setShowSidebarMobile}) => {
  const { user } = useSelector((state) => state.user);
  const showSidebar = useSelector((state) => state.ui.sidebarOpen);
  return (
    <header className="header-container">
      <MdMenu size={35} color='black' onClick={() => setShowSidebarMobile(!showSidebarMobile)}/>
      <img
          src={user[7] === 'Reseller' ? TTLogoWide : AthalosLogo}
          className={showSidebar ? "nav-logo-header" : 'nav-logo-hidden'}
          alt={user[7] === 'Reseller' ? TTLogoWide : 'logo-athalos'}
          />
      <FormButton className={'storebutton'} icon={<IoMdCart size={24} color='white'/>} />
  </header>
  );
};

export default HeaderComponent;
