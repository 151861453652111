// React/Redux imports 
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import ServiceBlock from '../../../components/service-block/service-block.component';

// Icons
import { IoIosSearch } from 'react-icons/io';

// Typography
import HeadingSecondary from '../../../components/typography/headings/heading-secondary/heading-secondary.component';



import '../../../screens/homescreen/homescreen.styles.scss';

const VasComponent = () => {
  const { user } = useSelector((state) => state.user); // get the user array
  const { defaultServices } = useSelector((state) => state.defaultServices);
  const [search, setSearch] = useState(''); // Search functionality
  const [userServices, setUserServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [src, setSrc] = useState('https://athalos.com');
  const [pageName, setPageName] = useState('Click on a button to get started!');

  useEffect(() => {
    function gettingUserServices() {
      const userServicesIds = user[8]?.split('&'); // service ID's are retrieved
      const userServicesArr = userServicesIds.map((el) => {
        return defaultServices.find((arr) => arr.includes(el.substring(0, 3)));  // ID's and default services are linked
      });

      setUserServices(userServicesArr); // services are stored in state
    }
    
    if (defaultServices.length) {
      gettingUserServices();
    }
  }, [user, defaultServices]);
  useEffect(() => {
    if (userServices.length) {
      const filtered = userServices.filter(
        (el) => el && el[2].toLowerCase().includes(search.toLowerCase())
      );

      setFilteredServices(filtered);
    }
  }, [search, userServices]);
  return (
          <div className="home-container">
            <div className="services-layout">
              <HeadingSecondary text={`Recently Used Services`} servicesCount={userServices.length} showArrow={true} link={true} linkTo={'/value-added-services'} mode={'dark'} secondLineText={'View your recently used services'}/>
              {/* <div className="services-layout-view">
                {filterItems.map((item, index) => (
                  <div
                    key={index}
                    index={index}
                    className={activeIndex === index ? 'active' : 'inactive'}
                    onClick={() => dispatch(setIndex(index))}
                  >
                    {item.icon}
                  </div>
                ))}
              </div> */}
            </div>
            <div className="services-search">
              <button type="submit" className="services-search-btn">
                <IoIosSearch />
              </button>
              <input
                  type="text"
                  className="services-search-input"
                  placeholder="Search for service"
                  onChange={(event) => {
                    setSearch(event.target.value);
                  }}
                ></input>
            </div>
            <div className='tiles-container-scrollbar'>
              {defaultServices.constructor === Array ? (
                filteredServices.map((e, i) => {
                  return <ServiceBlock key={i} props={e} setSrc={setSrc} setPageName={setPageName} />
                })
              ) : (
                <p style={{ color: 'white' }}>{defaultServices}</p>
              )}
            </div>
          </div>
  );
};

export default VasComponent;
