import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import FormInput from '../../components/form-input/form-input.component';
import './create-accountscreen.styles.scss';
import AthalosLogo from '../../assets/img/athalos-logo.png';
import gradientBlue from '../../assets/img/gradient-blue.svg';
import gradientPurple from '../../assets/img/gradient-purple.svg';
import { MdError } from 'react-icons/md';
import FormButton from '../../components/form-button/form-button.component';
import BackgroundGradient from '../../components/layouts/backgroud-gradient/background-gradient.component';
import { useAuth } from '../../AuthContext';
import { IoMdArrowBack } from 'react-icons/io';
import OWNLogo from '../../assets/img/OWN-Logo.png';
import ResellerLogo from '../../assets/img/reseller.png';
import MediamarktLogo from '../../assets/img/mediamarkt-logo.webp';
import TTLogo from '../../assets/img/tt-logo.webp';

const CreateAcccountScreen = () => {
  const navigate = useNavigate();
  const location = useLocation(); 
  const { logo } = location.state || {}; 
  const { authState, setAuthState } = useAuth();
  const [errMsg, setErrMsg] = useState('');

  const handleCreateAccount = async (e) => {
    e.preventDefault();
    navigate('/first-login');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAuthState((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <div className="signin-container">
      <div className="signin-gradient-container">
        <BackgroundGradient className="bg-gradient bg-gradient--blue" gradientImg={gradientBlue} />
        <BackgroundGradient
          className="bg-gradient bg-gradient--purple"
          gradientImg={gradientPurple}
          blur="50px"
        />
      </div>
      <img
        className="signin-logo"
        src={
          logo === 'OWN' ? OWNLogo :
          logo === 'ADMIN' ? AthalosLogo :
          logo === 'Reseller' ? ResellerLogo :
          logo === 'TT' ? TTLogo :
          logo === 'mediamarkt' ? MediamarktLogo :
          AthalosLogo
        }
        alt="logo-athalos"
      />
      <form onSubmit={handleCreateAccount}>
        <Link className="create-account-heading" to={'/signin'}>
          <IoMdArrowBack size={18} color="var(--signin-text)" />
          <div className="create-account-heading-text">Back</div>
        </Link>
        <h1 className="signin-heading">Create Account</h1>
        <p className="signin-heading-support">Create an account to manage your SIM(s)</p>
        <FormInput
          handleChange={handleChange}
          name="username"
          type="text"
          id="username"
          label="username"
          required
        />
        <FormInput
          handleChange={handleChange}
          name="password"
          type="password"
          id="password"
          label="password"
          required
        />
        {errMsg && (
          <div className="signin-error">
            <MdError size={15} />
            <p className="signin-error-text">{errMsg}</p>
          </div>
        )}
        <FormButton className="form-btn" type="submit" text="Create Account" />
      </form>
    </div>
  );
};

export default CreateAcccountScreen;
