import { createSlice } from '@reduxjs/toolkit';

export const defaultServicesSlice = createSlice({
  name: 'defaultServices',
  initialState: {
    defaultServices: [],
  },
  reducers: {
    setDefaultServices: (state, action) => {
      state.defaultServices = action.payload;
    },
  },
});

export const { setDefaultServices } = defaultServicesSlice.actions;

export default defaultServicesSlice.reducer;
