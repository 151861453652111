import React from 'react';
import './heading-secondary.styles.scss';
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from 'react-router-dom';

const HeadingSecondary = ({ text, secondLineText, servicesCount, showArrow, link, linkTo, mode }) => (
  <div className={`heading-secondary ${link ? 'with-link' : ''} ${mode}`} style={link ? { cursor: 'pointer' } : {}}>
    {link ? (
      <Link to={linkTo} className="heading-secondary-link">
        <span>{`${text} ${servicesCount ? `(${servicesCount})` : ''}`} 
          {showArrow && <MdKeyboardArrowRight className={`heading-secondary-arrow ${mode}`}/>}
        </span>
        {secondLineText && <div className="second-line-text">{secondLineText}</div>}
      </Link>
    ) : (
      <>
        <span>{`${text} ${servicesCount ? `(${servicesCount})` : ''}`} 
          {showArrow && <MdKeyboardArrowRight className={`heading-secondary-arrow ${mode}`}/>}
        </span>
        {secondLineText && <div className="second-line-text">{secondLineText}</div>}
      </>
    )}
  </div>
);

export default HeadingSecondary;
