import React from 'react';

import './primary-btn.styles.scss';

const PrimaryBtn = ({
  text,
  href,
  click,
  icon,
  cname,
}) => {
  return (
    <a href={href} target="_blank" rel="noreferrer noopener" style={{ textDecoration: 'none' }}>
      <button onClick={click} className={`${cname} btn-primary`}>
        {text}
        {icon}
      </button>
    </a>
  );
};

export default PrimaryBtn;