import React from 'react';
import './form-button.styles.scss';

const FormButton = ({ text, className, icon, onClick, ...props }) => (
  <button
    className={`${className} form-button`}
    onClick={onClick} 
    {...props}
  >
    {icon && <span className="button-icon">{icon}</span>}
    {text}
  </button>
);

export default FormButton;
