import React from 'react';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts';

const DonutChart = ({ data, primaryColor, outerRadius = 80, innerRadius = 60, title }) => {
  // Function to customize the label inside the pie
  const renderCustomizedLabel = ({ cx, cy }) => {
    return (
      <g>
        <text x={cx} y={cy} textAnchor="middle" fill={'var(--heading-color-dark)'} fontSize={24} fontWeight="bold">
          {data[1].value}/{data[2].value}
        </text>
        <text x={cx} y={cy + 27} textAnchor="middle" fill={'var(--heading-color-dark)'} fontSize={14} fontWeight="normal">
          {title}
        </text>
      </g>
    );
  };

  const renderColorfulLegendText = (value, entry) => {
    return (
      <span style={{
        color: entry.color,
        fontSize: '15px',
        verticalAlign: 'middle'  // Ensures vertical centering with the icon
      }}>
        {value}
      </span>
    );
  };

  // Exclude the last item from the legend
  const legendData = data.slice(0, -1);
  
  const coloredData = data.map((item, index) => ({
    ...item,
    fill: index === 0 ? primaryColor : '#d0d0e1' // Assuming the primary color applies to the first item
  }));

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={coloredData}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={outerRadius}
          innerRadius={innerRadius}
          fill="#8884d8"
          dataKey="value"
          stroke='none'
        >
          {coloredData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.fill} />
          ))}
        </Pie>
        <Legend
          align="right"
          verticalAlign="middle"
          layout="vertical"
          iconType="circle"
          iconSize={15}
          formatter={renderColorfulLegendText}
          payload={legendData.map((item, index) => ({
            value: item.name,
            type: 'circle',
            color: coloredData[index].fill
          }))}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DonutChart;
