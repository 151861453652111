import React from 'react';
import './heading-primary.styles.scss';

const HeadingPrimary = ({ text, secondLineText, mode }) => (
  <h2 className={`heading-primary ${mode === 'dark' ? 'dark-mode' : ''}`}>
    {secondLineText && <div className="second-line-text">{secondLineText}</div>}
    <div>{text}</div>
  </h2>
);

export default HeadingPrimary;