import { React, useState}  from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';
import { logoutUser } from '../../redux/userSlice';
import { menu, account } from './sidebarData';
import AthalosLogo from '../../assets/img/athalos-logo.png';
import ResellerLogo from '../../assets/img/reseller.png';
import AdminLogo from '../../assets/img/admin.png';
import OWNLogo from '../../assets/img/OWN-Logo.png';
import OWNLogoWide from '../../assets/img/own-logo-wide.png';
import MediamarktLogo from '../../assets/img/mediamarkt-logo.webp';
import TTLogo from '../../assets/img/tt-logo.webp';
import './sidebar.styles.scss';
import FormControlLabel from '@mui/material/FormControlLabel';
import { MdLocalMall } from "react-icons/md";
import { MdOutbox } from "react-icons/md";


import { useSelector, useDispatch } from 'react-redux';
import { toggleSidebar, toggleKpiSubmenu, toggleSimSubmenu, toggleResellerSubMenu } from '../../redux/uiSlice';
import { MdLogout } from 'react-icons/md';
import { BiBorderNone } from 'react-icons/bi';
import { reseller, admin, TT, newUser } from './sidebarData';
import { MdLocalGroceryStore } from 'react-icons/md';
import HeadingSecondary from '../typography/headings/heading-secondary/heading-secondary.component';


const currentRole = {
  Reseller: reseller,
  Admin: admin,
  TT: TT,
  newUser: newUser
}; // overbodig, nog wijzigen


const Sidebar = () => {
  const showSidebar = useSelector((state) => state.ui.sidebarOpen);
  const { user } = useSelector((state) => state.user);
  const kpiSubmenuCollapsed = useSelector((state) => state.ui.kpiSubmenuCollapsed);
  const simSubmenuCollapsed = useSelector((state) => state.ui.simSubmenuCollapsed);
  const resellerSubmenuCollapsed = useSelector((state) => state.ui.resellerSubmenuCollapsed)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const sideBarItems = currentRole[user[7]] || []

  const isMobile = window.innerWidth <= 766;
  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  const handleLogout = () => {
    sessionStorage.clear(); 
    dispatch(logoutUser());
    navigate('/signin', { state: { logo: user[7] } });
  };
  return (
    <div className="sidebar-container">
      <nav className={showSidebar ? 'nav' : 'nav collapsed'}>
      {!isMobile && (
        <div className='brand-logo-container'>
          <img
            src={
              user[7] === 'OWN' ? OWNLogo :
              user[7] === 'ADMIN' ? AthalosLogo :
              user[7] === 'Reseller' ? ResellerLogo :
              user[7] === 'TT' ? TTLogo :
              user[7] === 'mediamarkt' ? MediamarktLogo :
              AthalosLogo
            }
            className={showSidebar ? "nav-logo-header" : 'nav-logo-hidden'}
            alt={
              user[7] === 'OWN' ? 'logo-athalos' :
              user[7] === 'ADMIN' ? 'logo-athalos' :
              user[7] === 'Reseller' ? 'TTLogo' :
              user[7] === 'TT' ? 'TTLogo' :
              'logo-athalos'
            }
          />
        </div>
      )}
        <div className='top-container'>
          <ul className="nav-items">
            {sideBarItems.map((item, index) => (
              <li key={index} className="nav-li">
                {item.subNav ? (
                  <div
                    className={`${showSidebar ? item.cName : `${item.cName} hidden`}`}
                    onClick={() => {
                      if (item.title === 'KPI-Portal') {
                        dispatch(toggleKpiSubmenu());
                      } else if (item.title === 'Reseller') {
                        dispatch(toggleResellerSubMenu());
                      } else {
                        dispatch(toggleSimSubmenu());
                      }
                    }}
                  >
                    {item.icon}
                    <span className={showSidebar ? 'span' : 'span-active'}>{item.title}</span>
                    <div className={`item-toggle ${item.title === 'KPI-Portal' && !kpiSubmenuCollapsed ? 'item-toggle-rotated' : ''} ${item.title === 'Reseller' && !resellerSubmenuCollapsed ? 'item-toggle-rotated' : ''} ${item.title === 'Sim Overview' && !simSubmenuCollapsed ? 'item-toggle-rotated' : ''}`}>
                      {showSidebar && item.toggle}
                    </div>
                  </div>
                ) : (
                  <NavLink
                    to={item.path}
                    className={({ isActive }) => (isActive ? `${item.cName} nav-item--active` : item.cName)}
                  >
                    {item.icon}
                    <span>{item.title}</span>
                  </NavLink>
                )}
                {item.subNav && (
                  <ul className={`submenu ${item.title === 'KPI-Portal' ? (kpiSubmenuCollapsed ? '' : 'submenu-visible') : item.title === 'Reseller' ? (resellerSubmenuCollapsed ? '' : 'submenu-visible') : (simSubmenuCollapsed ? '' : 'submenu-visible')}`}>
                    {item.subNav.map((subItem, subIndex) => (
                      <li key={subIndex} className='nav-li-submenu'>
                        <NavLink
                          to={subItem.path}
                          className={({ isActive }) => (isActive ? `${subItem.cName} nav-item-submenu--active` : subItem.cName)}
                        >
                          {subItem.icon}
                          <span>{subItem.title}</span>
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
              </ul>
            </div>
            {/* <div className='bottom-container'> */}
              {/* <div className='nav-toggle'>
                <FiMenu size={28} onClick={handleToggleSidebar} />
              </div> */}
              {/* <div className='theme-switch'>
                <FormControlLabel
                  control={<MaterialUISwitch sx={{ m: 1 }} checked={theme === 'dark'} onChange={handleChange} />}
                />
              </div> */}
              {/* </div> */}
              <div className='bottom-container'> 
                {account.map((item, index) => (
                  <li key={index} className="nav-li">
                    <NavLink
                      to={item.path}
                      className={item.cName}
                    >
                      {item.icon}
                      <span>{item.title}</span>
                    </NavLink>
                  </li>
                ))}
              <li className="nav-li">
              <div className="nav-item" onClick={handleLogout}>
                  <MdLogout size={22} />
                  <span>Logout</span>
                </div>
              </li>
              </div>
              <div className='navbar-button-container'>
                <div className='nav-button sidebarstore'>
                  <MdLocalMall size={22} color='white'/> 
                  <a href='https://www.oneworldnumber.com/category/store-products' className='no-underline'> 
                    <div className='button-text sidebarstore'>Store</div>
                    </a>
                </div>
                <div className='nav-button feedback'>
                  <MdOutbox size={22} color='black'/>
                  <a className='no-underline' href={`mailto:olav@athalos?subject=Feedback%20on%20portal%20${encodeURIComponent(currentUrl)}%20page`}>
                    <div className='button-text feedback'>Give Feedback</div>
                  </a>
                </div>
                </div>
                
      </nav>
    </div>
    );
  };
              
export default Sidebar;
