import React, { useState, useEffect, useContext } from "react"
import { DataContext } from "./DataContext"

function TotalAcdGas({ className, selectedRange }) {
  const [acd, setAcd] = useState(null)
  const { records, dailyrecords } = useContext(DataContext)

  useEffect(() => {
    if (records && records.length > 0) {
      let filteredrecords
      if (selectedRange === "Today") {
        filteredrecords = dailyrecords
      } else if (selectedRange === "Last 7 days") {
        filteredrecords = records.slice(0, 7)
      } else if (selectedRange === "Last Month") {
        filteredrecords = records
      } else {
        filteredrecords = dailyrecords
      }

      let totalAnsweredDuration = 0
      let totalAnsweredCalls = 0

      filteredrecords.forEach(record => {
        const parts = record.split(">>")
        parts.forEach(part => {
          const [key, value] = part.split("=")
          if (key === "durm") {
            totalAnsweredDuration += parseInt(value, 10)
          } else if (key === "callsa") {
            totalAnsweredCalls += parseInt(value, 10)
          }
        })
      })
      const calculatedAcd =
        totalAnsweredCalls > 0 ? totalAnsweredDuration / totalAnsweredCalls : 0
      setAcd(calculatedAcd)
    }
  }, [selectedRange, records])

  return (
    
    <div className={className}>
      <div className="purple-text-big total-acd">
      {acd !== null ? acd.toFixed(2) : "N.A."}
      </div>
    </div>
  )
}

export default TotalAcdGas
