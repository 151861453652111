import { Navigate, Outlet } from 'react-router-dom';

export const ProtectedRoute = ({ user, redirectPath = '/signin', children }) => {
  // checks if user is signed in. If not, redirect.
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  // If the ProtectedRoute is used to wrap a component use children, otherwise Outlet.
  return children ? children : <Outlet />;
};
