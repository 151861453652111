import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';

const VideoChat = () => {
    const localVideoRef = useRef(null);
    const remoteVideoRef = useRef(null);
    const [isCaller, setIsCaller] = useState(false);
    const [callerCode, setCallerCode] = useState('');
    const [joinCode, setJoinCode] = useState('');

    const socket = useRef(null);
    const rtcPeerConnection = useRef(null);

    const iceServers = {
        iceServers: [
            { urls: 'stun:stun.services.mozilla.com' },
            { urls: 'stun:stun.l.google.com:19302' }
        ]
    };

    useEffect(() => {
        socket.current = io();
        setupSocketEvents();
        return () => {
            socket.current.disconnect();
        };
    }, []);

    const setupSocketEvents = () => {
        socket.current.on('candidate', event => {
            const candidate = new RTCIceCandidate(event);
            rtcPeerConnection.current.addIceCandidate(candidate);
        });

        socket.current.on('offer', param => {
            setCallerCode(param.caller); // Log the caller code when receiving an offer
            console.log("Received an offer from caller:", param.caller);
            rtcPeerConnection.current.setRemoteDescription(new RTCSessionDescription(param.sdp));
            rtcPeerConnection.current.createAnswer()
                .then(desc => setLocalAndAnswer(desc))
                .catch(e => console.log(e));
        });

        socket.current.on('answer', event => {
            console.log("Received an answer from caller:", callerCode);
            rtcPeerConnection.current.setRemoteDescription(new RTCSessionDescription(event.sdp));
        });

        socket.current.on('ready', code => {
            console.log('Receiver ready at', code);
            setIsCaller(true);
            setCallerCode(code); // Set caller code when receiver is ready
            createPeerConnection();
            rtcPeerConnection.current.createOffer({ offerToReceiveVideo: 1 })
                .then(desc => setLocalAndOffer(desc))
                .catch(e => console.log(e));
        });
    };

    const setLocalAndOffer = sessionDescription => {
        rtcPeerConnection.current.setLocalDescription(sessionDescription);
        socket.current.emit('offer', {
            type: 'offer',
            sdp: sessionDescription,
            receiver: joinCode // Assuming 'joinCode' is the code entered by the user to join a call
        });
    };

    const setLocalAndAnswer = sessionDescription => {
        rtcPeerConnection.current.setLocalDescription(sessionDescription);
        socket.current.emit('answer', {
            type: 'answer',
            sdp: sessionDescription,
            caller: callerCode
        });
    };

    const startCall = () => {
        createPeerConnection();
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
            .then(stream => {
                localVideoRef.current.srcObject = stream;
                rtcPeerConnection.current.addStream(stream);
            }).catch(error => console.log('MediaStream Error:', error));
    };

    const createPeerConnection = () => {
        rtcPeerConnection.current = new RTCPeerConnection(iceServers);
        rtcPeerConnection.current.onicecandidate = event => {
            if (event.candidate) {
                socket.current.emit('candidate', {
                    type: 'candidate',
                    label: event.candidate.sdpMLineIndex,
                    id: event.candidate.sdpMid,
                    candidate: event.candidate.candidate,
                    sendTo: isCaller ? joinCode : callerCode
                });
            }
        };
        rtcPeerConnection.current.onaddstream = event => {
            remoteVideoRef.current.srcObject = event.stream;
        };
    };

    return (
        <div>
            <video ref={localVideoRef} autoPlay></video>
            <video ref={remoteVideoRef} autoPlay></video>
            <button onClick={startCall}>Start Call</button>
            <input value={joinCode} onChange={e => setJoinCode(e.target.value)} placeholder="Enter join code" />
        </div>
    );
};

export default VideoChat;
