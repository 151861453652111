import './resellerscreen.styles.scss';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import Sidebar from '../../components/sidebar/sidebar.component';
import HeaderComponent from '../../components/header-component/header.component';
import HeadingPrimary from '../../components/typography/headings/heading-primary/heading-primary.component';


// import ResellerScreen from '../resellerscreen/resellerscreen.component';
import ResellerOverviewScreen from '../reselleroverviewscreen/reselleroverviewscreen.component';
import ResellerSoldSimsScreen from '../resellersimscreen/resellersimscreen.component';
import ResellerProductScreen from '../resellerproductscreen/resellerproductscreen.component';
import ExpiredScreen from '../resellerexpiredcomponent/expired.component';
import ExceptionScreen from '../resellerexceptionscomponent/exceptions.component';
import ResellerReportScreen from '../resellerreportscreen/resellerreportscreen.component';
const ResellerScreen = () => {
    const showSidebar = useSelector((state) => state.ui.sidebarOpen);
    const isMobile = window.innerWidth <= 766;  
    const [showSidebarMobile, setShowSidebarMobile] = useState(false);
    const [activeTab, setActiveTab] = useState('Inventory'); // Default active tab
    const [inventoryCount, setInventoryCount] = useState('')
    const [soldCount, setSoldCount] = useState('')
    const [expirationCount, setExpirationCount] = useState('')
    const [exceptionCount, setExceptionCount] = useState('')
    const [productCount, setProductCount] = useState('')
    return (
      <div className='header-layout'>
        <div className="bottom-nav-container">
          <HeaderComponent showSidebarMobile={showSidebarMobile}
          setShowSidebarMobile={setShowSidebarMobile} />
        </div>
        <div className='portalscreen-layout'>
          {isMobile ? (
            <div className={`sidebar-container-mobile ${showSidebarMobile ? 'sidebar-visible' : ''}`}>
              <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
                <Sidebar />
              </div>
            </div>
          ) : (
            <div className={`sidebar-container ${showSidebar ? 'expanded' : ''}`}>
              <Sidebar />
            </div>
          )}
          <div className='content-container'>
            <div className='heading-container'>
              <HeadingPrimary text={'Reseller Screen'} mode={'dark'}/>
            </div>
            <div className='tab-menu-container'>
              <div className="tabs-wrapper">
                <button className={`tab ${activeTab === 'Inventory' ? 'active' : ''}`} onClick={() => setActiveTab('Inventory')}>{`Inventory (${inventoryCount})`}</button>
                <button className={`tab ${activeTab === 'Sold Sims' ? 'active' : ''}`} onClick={() => setActiveTab('Sold Sims')}>{`Sold SIMs (${soldCount})`}</button>
                <button className={`tab ${activeTab === 'Expired SIMs' ? 'active' : ''}`} onClick={() => setActiveTab('Expired SIMs')}>{`Expired SIMs (${expirationCount})`}</button>
                <button className={`tab ${activeTab === 'Exceptions' ? 'active' : ''}`} onClick={() => setActiveTab('Exceptions')}>{`Exceptions (${exceptionCount})`}</button>
                <button className={`tab ${activeTab === 'Products' ? 'active' : ''}`} onClick={() => setActiveTab('Products')}>{`Products (${productCount})`}</button>
                <button className={`tab ${activeTab === 'Reports' ? 'active' : ''}`} onClick={() => setActiveTab('Reports')}>Reports</button>
              </div>
              <div className="tab-content">
                {activeTab === 'Inventory' && <ResellerOverviewScreen setInventoryCount={setInventoryCount}/>}
                {activeTab === 'Sold Sims' && <ResellerSoldSimsScreen setSoldCount={setSoldCount} />}
                {activeTab === 'Products' && <ResellerProductScreen setProductCount={setProductCount}/>}
                {activeTab === 'Expired SIMs' && <ExpiredScreen setExpirationCount={setExpirationCount}/>}
                {activeTab === 'Exceptions' && <ExceptionScreen setExceptionCount={setExceptionCount}/>}
                {activeTab === 'Reports' && <ResellerReportScreen setExceptionCount={setExceptionCount}/>}
            </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default ResellerScreen;
