export function parseCSV(data) {
  const lines = data.trim().split("\n")
  const header = lines[0].split(";")

  const entries = lines.slice(1).map(line => {
    const values = line.split(";")
    return header.reduce((obj, field, index) => {
      obj[field] = values[index]
      return obj
    }, {})
  })
  return entries

}
