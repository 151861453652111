import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import FormInput from '../../components/form-input/form-input.component';
import './signin-and-signup.styles.scss';
import logo from '../../assets/icons/Logo no text.svg';
import AthalosLogo from '../../assets/img/athalos-logo.png';
import gradientBlue from '../../assets/img/gradient-blue.svg';
import gradientPurple from '../../assets/img/gradient-purple.svg';
import { MdError } from 'react-icons/md';
import FormButton from '../../components/form-button/form-button.component';
import BackgroundGradient from '../../components/layouts/backgroud-gradient/background-gradient.component';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../redux/userSlice.js';
import { signin } from '../../api/apiFunctions.js';
import { fetchTokenWithCredentials } from '../../api/TokenService.js';
import OWNLogo from '../../assets/img/OWN-Logo.png';
import ResellerLogo from '../../assets/img/reseller.png';
import MediamarktLogo from '../../assets/img/mediamarkt-logo.webp';
import TTLogo from '../../assets/img/tt-logo.webp';
import { useAuth } from '../../AuthContext';
import { Link } from 'react-router-dom';

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); 
  const { logo } = location.state || {}; 
  const { authState, setAuthState } = useAuth();
  const [errMsg, setErrMsg] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    const { username, password } = authState;
    const tokenResponse = await fetchTokenWithCredentials(username, password);
    signin(username, password, setErrMsg)
      .then((user) => dispatch(loginUser(user)))
      .then(({ payload }) => {
        if (payload) {
          navigate('/home');
        } else if (tokenResponse) {
          navigate('/first-login');
        }
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAuthState((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <div className="signin-container">
      <div className="signin-gradient-container">
        <BackgroundGradient className="bg-gradient bg-gradient--blue" gradientImg={gradientBlue} />
        <BackgroundGradient
          className="bg-gradient bg-gradient--purple"
          gradientImg={gradientPurple}
          blur="50px"
        />
      </div>
      <img
        className="signin-logo"
        src={
          logo === 'OWN' ? OWNLogo :
          logo === 'ADMIN' ? AthalosLogo :
          logo === 'Reseller' ? ResellerLogo :
          logo === 'TT' ? TTLogo :
          logo === 'mediamarkt' ? MediamarktLogo :
          AthalosLogo
        }
        alt="logo-athalos"
      />

      <form onSubmit={handleLogin}>
        <h1 className="signin-heading">Sign in</h1>
        <p className="signin-heading-support">Login to manage your account</p>
        <FormInput
          handleChange={handleChange}
          name="username"
          type="text"
          id="username"
          label="username"
          required
        />
        <FormInput
          handleChange={handleChange}
          name="password"
          type="password"
          id="password"
          label="password"
          required
        />

        {errMsg && (
          <div className="signin-error">
            <MdError size={15} />
            <p className="signin-error-text">{errMsg}</p>
          </div>
        )}
  
        <FormButton className="form-btn" type="submit" text="Sign in" />
  
        <p className="form-help-text form-help-text--grey">First time here?</p>
        <p className="form-help-text">
        <Link to={'/create-account'} className="form-help-text__link create-account-text">
            Create Account
        </Link>
        </p>
      </form>
    </div>
  );
};

const SigninAndSignup = () => (
  <div>
    <SignIn />
  </div>
);

export default SigninAndSignup;
