import React, { useState, useContext } from 'react';
import './userform.styles.scss'; // Ensure you create this CSS file
import HeadingPrimary from '../../components/typography/headings/heading-primary/heading-primary.component';
import { SimContext } from '../../api/SimContext';
const BasicForm = () => {
const { createSubUser} = useContext(SimContext);
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    phone: '',
    email: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createSubUser(formData.email, formData.name, formData.phone, formData.surname );
  };

  return (
    <form onSubmit={handleSubmit} className="basic-form">
    <HeadingPrimary text={'Add user'} mode={'dark'}/>
      <div className="user-form-group">
        <label className="user-form-label">Name:</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="user-form-input"
          required
        />
      </div>
      <div className="user-form-group">
        <label className="user-form-label">Surname:</label>
        <input
          type="text"
          name="surname"
          value={formData.surname}
          onChange={handleChange}
          className="user-form-input"
          required
        />
      </div>
      <div className="user-form-group">
        <label className="user-form-label">Phone Number:</label>
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          className="user-form-input"
          required
        />
      </div>
      <div className="user-form-group">
        <label className="user-form-label">Email:</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="user-form-input"
          required
        />
      </div>
      <button type="submit" className="user-form-button">Submit</button>
    </form>
  );
};

export default BasicForm;
